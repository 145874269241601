import { getMotorQuestionResult } from '../helpers/get-motor-question-result';
import { mapToAnswerType } from '../helpers/map-to-answer-type';
import { getInputQuestionResult, getNumberQuestionResult } from '../helpers/get-question-result';
import { CarUsageDetails } from './index';

export const mapUsageDetailsFromForm = (values: any): CarUsageDetails => {
  const motor = getMotorQuestionResult(
    mapToAnswerType(values.motor),
    values.engineReplacementType,
  );
  const repainted = getInputQuestionResult(
    mapToAnswerType(values.repainted),
    values.repaintedAnswer,
  );
  const damaged = getInputQuestionResult(
    mapToAnswerType(values.damaged),
    values.damagedAnswer,
  );
  const chipTuned = {
    answer: mapToAnswerType(values.chipTuned),
    details:
      values.chipTuningDocumentation === 'dontKnow'
        ? null
        : values.chipTuningDocumentation
  }

  const timeBeltChanged = getNumberQuestionResult(
    mapToAnswerType(values.timingBeltChanged),
    values.timingBeltChangedAnswer,
  );

  return {
    motor,
    repainted,
    damaged,
    chipTuned,
    timeBeltChanged,
    serviceInTime: values.serviceDocumentation,
    tires: values.tires,
  } satisfies CarUsageDetails;

}