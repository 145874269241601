import { CheckCard, ProForm } from '@ant-design/pro-components';
import { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import { HttpError, useCreate, useCustom, useGo, useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { Button, Divider, Flex, Form, Result, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import Skeleton from '@ant-design/pro-skeleton';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarInfo } from '../../components/car-info';
import { YesNoDontKnowOptionSelector } from '../../components/yes-or-no';
import { ICarSubmissionDetailsV3 } from '../../provider/dto/buyer-submission.dto';
import { BuyerSubmissionDetails } from './vo/route-params';
import { ForbiddenError } from '../../components/accept-price';
import { useAuthContext } from '../../contexts/auth';
import { BuyerUpsaleDetails } from './vo/buyer-details';
import InsuranceCoverageGuide from '../../assets/dk_folder_xg_diamond.pdf';

const { Text } = Typography;


export const BuyerSubmissionPage: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const { user } = useAuthContext();
  const { params } = useParsed<BuyerSubmissionDetails>();

  useDocumentTitle({ i18nKey: 'page.buyerCarForm.pageMetaTitle' });
  const { mutateAsync, isLoading: isLoadingCreate } = useCreate<BuyerUpsaleDetails>();
  const go = useGo();

  const { t } = useTranslation();


  const dealIdFromUrl = useMemo(() => {
    return params?.id;
  }, [params]);

  const { data, isLoading, isError } = useCustom<ICarSubmissionDetailsV3, HttpError>({
    url: `buyer-submission/car-submission-details/v3?dealId=${dealIdFromUrl}`,
    method: 'get',
    errorNotification: false,
    queryOptions: {
      enabled: !!dealIdFromUrl,
    },
  });

  if (isLoading && dealIdFromUrl || !data?.data) {
    return <Skeleton type="result" />;
  }

  const buyerDealData = data?.data;
  const deal = buyerDealData?.deal;

  const buyerId = buyerDealData.customerInfo.id;
  const dealId = deal.id;

  if (isError || !dealId || !buyerId) {
    return <Result
      status="500"
      title={t('global.error')}
      subTitle={t('global.unexpectedError')}
      extra={<a href="mailto:hej@aksel.nu">{t('global.contactSupport')}</a>}
    ></Result>;
  }

  if (!user?.email) {
    return <ForbiddenError />;
  }

  return <ProForm
    onFinish={async (values) => {
      await mutateAsync({
        successNotification: () => false,
        resource: `deal/${dealId}/provide-buyer-upsale-details`,
        values: {
          ...values,
          ...{
            buyerId,
            dealId,
          },
        },
      });

      go({ to: `/biler/${deal.id}` });

    }}

    submitter={{
      submitButtonProps: false,
      resetButtonProps: false,
      render: () => [],
    }}
    style={{ maxWidth: 820, margin: '0 auto', padding: 24 }}
  >
    <CarInfo {...buyerDealData?.deal} />
    <Divider>Forsikring</Divider>
    <Flex vertical={true} gap={'small'}>
      <Text>Det er lovpligtigt at have forsikret sin bil, men derfor behøver man jo ikke betale mere end
        højst nødvendigt.</Text>
      <Text>Vi har derfor forhandlet en stærk aftale hjem med Topdanmark, der giver dig <b>20% rabat på
        forsikringen</b> det første år – også selvom du allerede er kunde hos Topdanmark.</Text>

      <Text>
        Du kan nemt få et uforpligtende tilbud, så du kan sammenligne det med, hvad du ellers
        har indhentet. Over 60% af vores kunder oplever, at de får det bedste tilbud fra
        Topdanmark – det er da værd at finde ud af om det også gælder dig.
      </Text>

      <Form.Item
        label={t('page.buyerCarForm.interestedInInsurance')}
        name="wantInsuranceOffer"
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group
          style={{ width: '100%' }}
          size="small"
        >
          <YesNoDontKnowOptionSelector />
        </CheckCard.Group>
      </Form.Item>
    </Flex>

    <Divider>Ejerskifteforsikring</Divider>
    <Flex vertical={true} gap={'small'}>
      <Text>Vi vil gerne sikre, at du har helt ro i maven når du køber bil gennem Aksel.</Text>
      <Text>Vi tilbyder derfor en omfattende ejerskifteforsikring, der dækker uforudsete reparationsomkostninger med <b>0 kr. i selvrisiko.</b></Text>

      <Text>Det koster i gennemsnit <Link target={'_blank'}
                                          href={'https://blog.bilbasen.dk/hvad-er-driftsomkostningerne-ved-en-brugt-bil-442390/'}>9-10.000
        kr om året i reparationer</Link> for en 5 år gammel bil.</Text>
      <Text>Men fra kun <b>4.100 kr</b> er du med vores ejerskifteforsikring dækket i <b>12 måneder</b>.</Text>

      <Text>Se detaljer om dækningen <Link target={'_blank'}
                                           href={InsuranceCoverageGuide}>her</Link>. Dækning og pris afhænger af bilens alder, hk/kw og km.</Text>


      <Form.Item
        label={t('page.buyerCarForm.interestedInCarOwnershipInsurance')}
        name="wantChangeOfOwnershipInsuranceOffer"
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group
          style={{ width: '100%' }}
          size="small"
        >
          <YesNoDontKnowOptionSelector />
        </CheckCard.Group>
      </Form.Item>
    </Flex>

    <Form.Item>
      <Flex justify={'flex-end'}>
        <Button size={'large'} loading={isLoadingCreate} htmlType={'submit'} type={'primary'}
        >Videre</Button>
      </Flex>
    </Form.Item>

  </ProForm>;
};