import { CalendarOutlined, SunOutlined, SyncOutlined } from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import { Avatar, Flex, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../hooks';
import { SnowflakeOutlined } from '../../../shared/icons';

export const SEASONS = {
  SUMMER: 'SUMMER',
  WINTER: 'WINTER',
  BOTH: 'BOTH',
  ALL_SEASON: 'ALL_SEASON',
} as const;

export const WhatTiresComeWithCarQuestionForm = (
  { initialValues }: { initialValues?: keyof typeof SEASONS } = {},
) => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <Form.Item
      name="tires"
      label={t('page.dealDetails.uploadImagesForm.questions.tires.question')}
      rules={[{ required: true, message: t('global.requiredField') }]}
      initialValue={initialValues}
    >
      <CheckCard.Group style={{ width: '100%' }} size="small">
        <Flex vertical={isMobileWithTablet} justify={'flex-start'}>
          <CheckCard
            style={{ width: '100%' }}
            title={'Kun sommerdæk'}
            avatar={<Avatar icon={<SunOutlined />} size="small" />}
            value={SEASONS.SUMMER}
          />
          <CheckCard
            style={{ width: '100%' }}
            title={'Kun vinterdæk'}
            avatar={
              <Avatar
                icon={<SnowflakeOutlined height={'1.25em'} />}
                size="small"
              />
            }
            value={SEASONS.WINTER}
          />
        </Flex>{' '}
        <Flex vertical={isMobileWithTablet} justify={'flex-start'}>
          <CheckCard
            style={{ width: '100%' }}
            title={'Både sommer-og vinterdæk'}
            avatar={<Avatar icon={<SyncOutlined />} size="small" />}
            value={SEASONS.BOTH}
          />{' '}
          <CheckCard
            style={{ width: '100%' }}
            title={'Helårsdæk'}
            avatar={<Avatar icon={<CalendarOutlined />} size="small" />}
            value={SEASONS.ALL_SEASON}
          />
        </Flex>
      </CheckCard.Group>
    </Form.Item>
  );
};
