import { CheckCard, ProFormText } from '@ant-design/pro-components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { YesNoDontKnowOptionSelector } from '../../../yes-or-no';
import { useIsMobile } from '../../../../hooks';
import { DamagedAnswer } from '../vo';

export const CarDamageQuestionForm = (
  { initialValues }: { initialValues?: DamagedAnswer } = {},
) => {
  const { t } = useTranslation();
  const { isMobileWithTablet } = useIsMobile();

  return (
    <>
      <Form.Item
        label={t('page.dealDetails.uploadImagesForm.questions.damaged.question')}
        name="damaged"
        initialValue={
          initialValues ? ((initialValues?.answer === null || initialValues?.answer === undefined)
            ? 'dontKnow'
            : initialValues?.answer === 'YES') : undefined
        }
        rules={[{ required: true, message: t('global.requiredField') }]}
      >
        <CheckCard.Group style={{ width: '100%' }} size="small">
          <YesNoDontKnowOptionSelector
            fullWidth
            vertical={isMobileWithTablet}
            includeDontKnowOption
            justify="flex-start"
          />
        </CheckCard.Group>
      </Form.Item>

      <Form.Item
        style={{ paddingLeft: '24px' }}
        initialValue={initialValues ? initialValues?.details : undefined}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.damaged !== currentValues.damaged
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('damaged') === true ? (
            <ProFormText
              initialValue={initialValues ? initialValues?.details : undefined}
              name="damagedAnswer"
              label={t(
                'page.dealDetails.uploadImagesForm.questions.damaged.followup',
              )}
              placeholder={t(
                'page.dealDetails.uploadImagesForm.questions.damaged.placeholder',
              )}
              rules={[
                {
                  required: true,
                  message: t('global.requiredField'),
                },
              ]}
              formItemProps={{ style: { width: '100% ' } }}
            />
          ) : null
        }
      </Form.Item>
    </>
  );
};
