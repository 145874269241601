import { HttpError, useUpdate } from '@refinedev/core';
import React, { useState } from 'react';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import { getDefaultSortOrder, useTable } from '@refinedev/antd';
import { ClientData } from '../user/change-user-form';
import Skeleton from '@ant-design/pro-skeleton';
import { ErrorComponent } from '../error/404';
import { Button, Col, Flex, Form, Input, Row, Space, Table, Typography } from 'antd';
import { debounce } from '../../utils/debouence';
import { CheckCircleTwoTone, EditOutlined, StopTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ModalForm, ProFormSwitch, ProFormText } from '@ant-design/pro-components';

export const ClientsListPage = () => {
  useDocumentTitle({ i18nKey: 'page.clients.pageMetaTitle' });
  const [editRecord, setEditRecord] = useState<ClientData>();

  const { Title, Text } = Typography;
  const { Search } = Input;
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [search, setSearch] = useState('');

  const tableData = useTable<ClientData, HttpError, { search: string }>({
    resource: 'user',
    pagination: {
      mode: 'server',
      pageSize: 20,
    },
    meta: {
      search: search.trim(),
    },
    onSearch: (values: { search: string }) => {
      setSearch(values.search);
      return [];
    },
    sorters: {
      initial: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
    syncWithLocation: false,
    queryOptions: {
      retry: 0,
    },
  });

  const { searchFormProps, sorters, tableProps, tableQueryResult } = tableData;
  const { mutateAsync: updateClient, isLoading: isUpdatingClient } = useUpdate();

  const error = tableData.tableQueryResult?.error;
  const isLoading = tableData.tableQueryResult.isLoading;

  const isError = error?.statusCode && error?.statusCode > 399;

  return (
    (
      <>
        {isLoading ? (
          <Skeleton type="result" />
        ) : isError ? (
          <ErrorComponent />
        ) : (
          <Flex
            style={{ maxWidth: 1440, margin: '0 auto', padding: 24 }}
            vertical={true}
            gap={'small'}
            align={'center'}
          >
            <Title level={1}>{t('page.clients.pageMetaTitle')}</Title>

            <Flex
              style={{
                width: '100%',
                maxWidth: 1140,
              }}
              align={'center'}
              wrap={'wrap-reverse'}
              gap={'small'}
              justify={'space-between'}
            >
              <Form {...searchFormProps} layout="inline">
                <Form.Item name="search">
                  <Search
                    style={{
                      minWidth: '320px',
                    }}
                    size={'large'}
                    loading={isLoading}
                    onChange={debounce(searchFormProps.form?.submit, 400)}
                    placeholder={t(t('page.clients.searchByText'))}
                  />
                </Form.Item>
              </Form>
              <ModalForm<ClientData>
                loading={isUpdatingClient}
                title={t('global.edit')}
                open={!!editRecord}
                form={form}
                autoFocusFirstInput
                modalProps={{
                  destroyOnClose: true,
                  onCancel: () => {
                    setEditRecord(undefined);
                    form.resetFields();
                  },
                }}
                onFinish={async (values) => {
                  await updateClient({
                    id: editRecord!.id,
                    resource: `client`,
                    values,
                  });

                  await tableQueryResult.refetch();

                  setEditRecord(undefined);
                  form.resetFields();
                  return true;
                }}
              >
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={12}>
                    <ProFormText
                      name="firstName"
                      label={t('common.user.firstName.label')}
                      placeholder={t('common.user.firstName.placeholder')}
                      rules={[{ required: true, message: t('global.requiredField') }]}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <ProFormText
                      name="lastName"
                      label={t('common.user.lastName.label')}
                      placeholder={t('common.user.lastName.placeholder')}
                      rules={[{ required: true, message: t('global.requiredField') }]}
                    />
                  </Col>
                </Row>
                <ProFormText
                  name="email"
                  label={t('common.user.email.label')}
                  placeholder={t('common.user.email.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />
                <ProFormSwitch
                  name="validEmail"
                  label={t('common.user.validEmail.label')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />

                <ProFormText
                  name="phoneNumber"
                  label={t('common.user.phone.label')}
                  placeholder={t('common.user.phone.placeholder')}
                  rules={[{ required: true, message: t('global.requiredField') }]}
                />

              </ModalForm>
            </Flex>

            <Table
              {...tableProps}
              scroll={{ x: 1140 }}
              locale={{
                emptyText: t('common.noDataText'),
                filterReset: t('common.reset'),
                filterCheckall: t('common.selectAll'),
              }}
              rowKey="id"
            >
              <Table.Column
                width={120}
                render={(client: ClientData) => (
                  <Text>{client.name}</Text>
                )}
                title={t('common.client.name')}
              />
              <Table.Column
                width={160}
                render={(client: ClientData) => (
                  <Text>{client.email ?? 'N/A'}</Text>
                )}
                title={t('common.client.email')}
              />

              <Table.Column
                width={160}
                render={(client: ClientData) => (
                  <Text>{client.phoneNumber ?? 'N/A'}</Text>
                )}
                title={t('common.client.phone')}
              />
              <Table.Column
                width={160}
                render={(client: ClientData) => (
                  <Text>{client.profile?.address ?? 'N/A'}</Text>
                )}
                title={t('common.client.address')}
              />
              <Table.Column
                width={80}
                render={(client: ClientData) => (
                  <Text>{client.profile?.city ?? 'N/A'}</Text>
                )}
                title={t('common.client.city')}
              />
              <Table.Column
                width={60}
                render={(client: ClientData) => (
                  <Text>{client.profile?.zipCode ?? 'N/A'}</Text>
                )}
                title={t('common.client.zipCode')}
              />

              <Table.Column
                width={60}
                render={(client: ClientData) => (
                  <Text>
                    {
                      client.validEmail === true
                        ? <CheckCircleTwoTone twoToneColor={'green'} style={{ fontSize: 16 }} />
                        : client.validEmail === false
                          ? <StopTwoTone twoToneColor={'red'} style={{ fontSize: 16 }} />
                          : 'N/A'
                    }
                  </Text>
                )}
                title={t('common.client.validEmail')}
              />

              <Table.Column
                width={100}
                dataIndex="createdAt"
                title={t('common.createdAt')}
                sorter
                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                showSorterTooltip={false}
                render={(value: string) => (
                  <Text>
                    {new Date(value).toLocaleDateString()}{' '}
                    {new Date(value).toLocaleTimeString()}
                  </Text>
                )}
              />

              <Table.Column
                width={50}
                title={t('page.admin.deal.list.fields.details')}
                render={(record: ClientData) => {
                  return (
                    <Space direction={'horizontal'} wrap={true}>
                      <Button
                        onClick={() => {

                          const { profile, ...rest } = record;

                          form.setFieldsValue({
                              ...rest,
                              firstName: profile ? profile.firstName : rest.name.split(' ')[0],
                              lastName: profile ? profile.lastName : rest.name.split(' ').slice(1).join(' '),
                            },
                          );
                          setEditRecord(rest);
                        }}
                        type={'text'}
                        shape={'circle'}
                        icon={<EditOutlined />}
                      />
                    </Space>
                  );
                }}
              />

            </Table>
          </Flex>
        )}
      </>
    ));
};
