import { Authenticated, I18nProvider, Refine } from '@refinedev/core';
import { DevtoolsPanel, DevtoolsProvider } from '@refinedev/devtools';
import { RefineKbarProvider } from '@refinedev/kbar';

import { ThemedLayoutV2, useNotificationProvider } from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { App as AntdApp } from 'antd';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Footer, Header } from './components';
import ScrollToTop from './components/scroll/scroll-to-top';
import { CustomSider } from './components/sider';
import { useAuthContext } from './contexts/auth';
import { ColorModeContextProvider } from './contexts/color-mode';
import { AcceptPricePage } from './pages/accept-price';
import { AuthLayout, ForgotPasswordPage, LoginPage, ResetPasswordPage, SignupPage } from './pages/auth';
import { CarBuyerPage } from './pages/car-buyer';
import { CarsListPage } from './pages/cars-list';
import { DealDetailsPage } from './pages/deal-details';
import { DealsPage } from './pages/deals';
import { ErrorComponent } from './pages/error/404';
import { PartnerPortalPage } from './pages/partner';
import { PartnerB2bSubmissionPage } from './pages/partner-submit-car';
import { PartnerB2bSubmissionViewPage } from './pages/partner-submit-car-view';
import { UserProfile } from './pages/user/user-profile';
import { AuthGuard, dataProvider } from './provider';
import { createRefineAuthProvider } from './provider/auth';
import { setupAxiosInterceptors } from './provider/axios';
import { resources } from './routes';
import { queryClientOptions } from './utils/api';
import { BusinessAccountsPage } from './pages/business-accounts';
import { BuyerSubmissionPage } from './pages/buyer-submission';
import { ClientsListPage } from './pages/clients';


function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any) => {
      return `${t(key, options)}`;
    },
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { state, dispatch, isAdmin } = useAuthContext();
  setupAxiosInterceptors(dispatch);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <DevtoolsProvider>
              <Refine
                authProvider={createRefineAuthProvider(state, dispatch, t)}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: 'AeIZg8-YgnWrd-mUXPwC',
                  reactQuery: {
                    clientConfig: queryClientOptions,
                  },
                }}
                resources={resources}

              >
                <ScrollToTop />
                <Routes>
                  {/* Public Auth Routes - Redirect authenticated users to /biler page */}
                  <Route
                    element={<Outlet />}
                  >
                    <Route
                      element={
                        <>
                          <ThemedLayoutV2
                            Sider={() => null}
                            Header={Header}
                            Footer={Footer}
                          >
                            <Outlet />
                          </ThemedLayoutV2>
                        </>
                      }
                    >
                      <Route path="/auth" element={<AuthLayout />}>
                        <Route index element={<LoginPage />} />
                        <Route path="login" element={<LoginPage />} />
                        <Route path="tilmelding" element={<SignupPage />} />
                      </Route>
                      <Route
                        path="/auth/glemt-adgangskode"
                        element={<ForgotPasswordPage />}
                      />
                      <Route
                        path="/auth/nulstil-adgangskode"
                        element={<ResetPasswordPage />}
                      />
                    </Route>
                  </Route>

                  {/* Public Routes */}
                  <Route
                    element={
                      <>
                        <ThemedLayoutV2
                          Sider={() => null}
                          Header={Header}
                          Footer={Footer}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <Authenticated
                          key="home-page"
                          fallback={<NavigateToResource resource={isAdmin ? 'admin/biler' : 'biler'} />}
                        >
                          <NavigateToResource resource={isAdmin ? 'admin/biler' : 'biler'} />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="/buyer-submission/:base64Id"
                      element={<CarBuyerPage />}
                    />
                    <Route
                      path="/bekraeft-fremvisning/:base64Id"
                      element={<CarBuyerPage />}
                    />
                    <Route
                      path="/bekraeft-fremvisning/v2/:id"
                      element={<BuyerSubmissionPage />}
                    />
                    <Route
                      path="/partner/:organisationId"
                      element={<PartnerPortalPage />}
                    />
                    <Route
                      path="/partner/:organisationId/bil"
                      element={<PartnerB2bSubmissionPage />}
                    />
                    <Route
                      path="/partner/:organisationId/bil/:id"
                      element={<PartnerB2bSubmissionViewPage />}
                    />
                    <Route
                      path="/accept-pris/:dealId"
                      element={<AcceptPricePage />}
                    />
                  </Route>
                  {/* Authenticated Routes */}
                  <Route
                    element={
                      <AuthGuard
                        key="authenticated-routes"
                        fallback={<CatchAllNavigate to="/auth" />}
                      >
                        <ThemedLayoutV2
                          Sider={CustomSider}
                          Header={Header}
                          Footer={() => null}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </AuthGuard>
                    }
                  >
                    <Route path="/biler" element={<DealsPage />} />
                    <Route path="/biler/:id" element={<DealDetailsPage />} />
                    <Route path="/profil" element={<UserProfile />} />

                    <Route path="/admin/biler" element={<CarsListPage />} />
                    <Route path="/admin/clients" element={<ClientsListPage />} />
                    <Route path="/admin/business-accounts" element={<BusinessAccountsPage />} />
                  </Route>

                  <Route
                    path="*"
                    element={
                      <Authenticated
                        key="catch-all"
                        fallback={
                          <ThemedLayoutV2
                            Sider={() => null}
                            Header={Header}
                            Footer={Footer}
                          >
                            <ErrorComponent />
                          </ThemedLayoutV2>
                        }
                      >
                        <CatchAllNavigate to={isAdmin ? 'admin/biler' : 'biler'} />
                      </Authenticated>
                    }
                  />
                </Routes>

                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
              <DevtoolsPanel />
            </DevtoolsProvider>
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
