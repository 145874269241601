import { Flex } from 'antd';
import { ChipTuningQuestionForm } from './car-questionnaire/chip-tuned-question.form';
import { CarDamageQuestionForm } from './car-questionnaire/damage-question.form';
import { EngineQuestionForm } from './car-questionnaire/engine-question.form';
import { CarRepaintQuestionForm } from './car-questionnaire/repaint-question.form';
import { ServiceDocumentationQuestionForm } from './car-questionnaire/service-documentation-question.form';
import { TimingBeltChangeQuestionForm } from './car-questionnaire/timing-belt-question.form';
import { WhatTiresComeWithCarQuestionForm } from './car-questionnaire/tires.question.form';
import { CarUsageDetails, ServiceInTimeType } from './vo';

export const CarQuestionnaire = (
  { existingUsageDetails }: { existingUsageDetails?: CarUsageDetails } = {},
) => {
  return (
    <>
      <Flex vertical>
        <EngineQuestionForm initialValues={existingUsageDetails?.motor} />
        <CarRepaintQuestionForm initialValues={existingUsageDetails?.repainted} />
        <CarDamageQuestionForm initialValues={existingUsageDetails?.damaged} />
        <ChipTuningQuestionForm initialValues={existingUsageDetails?.chipTuned} />
        <TimingBeltChangeQuestionForm initialValues={existingUsageDetails?.timeBeltChanged} />
        <ServiceDocumentationQuestionForm initialValues={existingUsageDetails?.serviceInTime as ServiceInTimeType} />
        <WhatTiresComeWithCarQuestionForm initialValues={existingUsageDetails?.tires}/>
      </Flex>
    </>
  );
};
