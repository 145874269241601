import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'antd';
import { ModalForm, ProFormDigit, ProFormSwitch, ProFormText } from '@ant-design/pro-components';
import { useCustom, useCustomMutation } from '@refinedev/core';
import { EditOutlined } from '@ant-design/icons';

export interface ChangeUserDataFormProps {
  clientId?: string;
  title: string;
  onComplete?: () => Promise<unknown>;
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: number;
  validEmail: boolean;
}

export interface ClientData {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  validEmail: boolean;
  profile?: UserData;
}

export const ChangeUserDataForm = ({
                                     clientId,
                                     title,
                                     onComplete = () => Promise.resolve(),

}: ChangeUserDataFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const {
    refetch,
    isLoading: isLoadingUserData,
    data,
    failureReason,
  } = useCustom<UserData>({
    url: clientId ? `user/${clientId}` : 'user/profile',
    method: 'get',
    errorNotification: false,
    queryOptions: {
      useErrorBoundary: false,
      retry: 0,
    }
  });

  const { mutateAsync: updateUserProfile, isLoading: isUpdatingUserData} = useCustomMutation();

  const initialValues = data?.data;


  return (
    <ModalForm<UserData>
      loading={isLoadingUserData || isUpdatingUserData}
      width={600}
      title={t('global.edit') + ' ' + title}
      trigger={
        <Button type="primary" disabled={!!failureReason}>
          <EditOutlined />
          {t('global.edit') + ' ' + title}
        </Button>
      }
      form={form}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
      }}
      onFinish={async (values) => {
        const url = clientId ? `user/${clientId}` : 'user'
        await updateUserProfile({
          url,
          method: 'put',
          values,
        });
        await refetch();
        await onComplete();
        return true;
      }}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText
            initialValue={initialValues?.firstName}
            name="firstName"
            label={t('common.user.firstName.label')}
            placeholder={t('common.user.firstName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ProFormText
            initialValue={initialValues?.lastName}
            name="lastName"
            label={t('common.user.lastName.label')}
            placeholder={t('common.user.lastName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

      {
        clientId && <ProFormText
          name="email"
          label={t('common.user.email.label')}
          placeholder={t('common.user.email.placeholder')}
          initialValue={initialValues?.email}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      }
      {
        clientId && <ProFormSwitch
          name="validEmail"
          label={t('common.user.validEmail.label')}
          initialValue={initialValues?.validEmail}
          rules={[{ required: true, message: t('global.requiredField') }]}
        />
      }

      <ProFormText
        name="phoneNumber"
        initialValue={initialValues?.phoneNumber}
        label={t('common.user.phone.label')}
        placeholder={t('common.user.phone.placeholder')}
        rules={[{ required: true, message: t('global.requiredField') }]}
      />

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={8}>
          <ProFormText
            initialValue={initialValues?.address}
            name="address"
            label={t('common.user.address.label')}
            placeholder={t('common.user.address.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormText
            initialValue={initialValues?.city}
            name="city"
            label={t('common.user.addressCity.label')}
            placeholder={t('common.user.addressCity.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormDigit
            min={0}
            initialValue={initialValues?.zipCode}
            max={9999}
            fieldProps={{ precision: 0 }}
            name="zipCode"
            label={t('common.user.zipCode.label')}
            placeholder={t('common.user.zipCode.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>
    </ModalForm>
  );
};
