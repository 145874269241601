import ChangeOwnershipGuide from '../../assets/Guide til omregistrering via Motorregistrets hjemmeside.pdf';

import {
  AndroidOutlined,
  AppleFilled,
  CheckCircleTwoTone,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  DownloadOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  FieldTimeOutlined,
  FileOutlined,
  HeartOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LockTwoTone,
  PlusOutlined,
  RightOutlined,
  StopTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import {
  ModalForm,
  ProDescriptions,
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormMoney,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormTextArea,
  useToken,
} from '@ant-design/pro-components';
import Skeleton from '@ant-design/pro-skeleton';
import { HttpError, useCreate, useCustom, useGo, useNotification, useOne, useParsed } from '@refinedev/core';
import { useDocumentTitle } from '@refinedev/react-router-v6';
import {
  Button as DefaultButton,
  Col,
  Collapse,
  Flex,
  Form,
  Modal,
  Row,
  Space,
  Statistic,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ItemType } from 'rc-collapse/es/interface';
import { useTranslation } from 'react-i18next';
import { UploadImagesForm } from '../../components';
import { CarInfo } from '../../components/car-info';
import { LoadingComponent } from '../../components/shared/loading';
import { useAuthContext } from '../../contexts/auth';
import { Deal, RedemptionDetails, SecondOwnerDetails, UserToSignDetails } from '../../utils/deal-base.dto';
import { formatPriceToDanishLocale } from '../../utils/format-price-to-danish-locale';
import { DealStatus, FileUpload, findStatusOrder, getStatusOrder } from '../partner/vo/submission';
import { BuyerSubmissionPendingInfo, DealAllTimeStats, DealDailyStats, DealDetailsParam } from './vo/deal-details.dto';
import { ErrorComponent } from '../error/404';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ColumnChart from '@ant-design/plots/es/components/column';
import { SymblePayAccountDetails, SymblePayRedirect } from './vo/symble-pay.vo';
import { API_URL, dataProvider } from '../../provider';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { BusinessAccount } from '../business-accounts/vo/business-account.vo';
import dayjs from 'dayjs';
import FileUploader, { IFileUploaderProps } from '../../components/file-uploader';
import { axiosInstance } from '../../provider/axios';
import Link from 'antd/es/typography/Link';
import styled from 'styled-components';
import { formatDate, stringDateToISOString } from '../../utils/date';
import { addTrailingZerosToStart, allowOnlyNumbers } from '../../utils/number';
import { ChangeUserDataForm } from '../user/change-user-form';
import { EditDealFormModal } from '../../components/deal-details/upload-images/edit';


const IS_PRODUCTION_MODE = import.meta.env.PROD;


const Button = styled(DefaultButton)`
    min-width: 120px;
    height: auto;
    white-space: normal;
    padding: 4px 8px;
`;

const onDownloadAll = async (setDownloadingFiles: (b: boolean) => void, deal?: Deal) => {
  try {
    setDownloadingFiles(true);
    const carDetails = deal?.getCaradsId ? `${deal?.getCaradsId}` : `${deal?.make}-${deal?.model}-${deal?.year}`;

    const allImages = (deal?.images || []).map(async (img, idx) => {
      const imgName = `${idx}-` + img.originalName;

      const res = await fetch(
        `${dataProvider.getApiUrl()}/file/dealer-car/download-file/${img.urlKey}`,
      );

      const blob = await res.blob();
      return {
        imgName,
        blob,
      };
    });

    const zip = new JSZip();

    const folder = zip.folder(carDetails)!;

    const imagesDownloaded = await Promise.all(allImages);

    imagesDownloaded.forEach(img => {
      folder.file(img.imgName, img.blob, {
        binary: true,
      });
    });

    await zip.generateAsync({ type: 'blob' }).then(function(content) {
      // see FileSaver.js
      saveAs(content, `${carDetails}.zip`);
    });
  } finally {
    setDownloadingFiles(false);
  }
};


const WaitingIcon = <ClockCircleTwoTone twoToneColor={'gray'} style={{ fontSize: 24 }} />;
const NotAvailableIcon = <LockTwoTone twoToneColor={'grey'} style={{ fontSize: 24 }} />;
const RequireActionIcon = <WarningTwoTone twoToneColor={'orange'} style={{ fontSize: 24 }} />;
const FinishedStepIcon = <CheckCircleTwoTone twoToneColor={'green'} style={{ fontSize: 24 }} />;
const AcceptPriceComponent = ({ dealId }: { dealId: string }) => {
  return (
    <Flex>
      <Button type="primary" href={`/accept-pris/${dealId}`}>Accepter pris</Button>
      <WarningTwoTone
        twoToneColor={'orange'}
        style={{ fontSize: 24, marginLeft: 12 }}
      />
    </Flex>
  );
};


const RedemptionStatementForm = ({
                                   takeoverDate,
                                   dealId, onComplete = () => {
  },
                                 }: { takeoverDate: string | undefined, dealId?: string, onComplete?: () => void }) => {
  const [form] = Form.useForm<RedemptionDetails>();
  const fileUploader = useRef<IFileUploaderProps>(null);

  const { Text } = Typography;

  const [fiKort, setFiKort] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createRedemptionForm } = useCreate<RedemptionDetails>({
    mutationOptions: {
      onSettled() {
        setIsLoading(false);
      },
    },
  });
  const { t } = useTranslation();

  return <ModalForm<RedemptionDetails>
    loading={isLoading}
    width={600}
    title={'Indsend indfrielsesopgørelse'}
    trigger={
      <Button type="primary">
        <PlusOutlined />
        Indsend
      </Button>
    }
    form={form}
    autoFocusFirstInput
    modalProps={{
      destroyOnClose: true,
    }}
    onFinish={async (values) => {
      setIsLoading(true);
      const uploadResult = await fileUploader.current!.upload();

      const files: FileUpload[] = (IS_PRODUCTION_MODE ? uploadResult.successful : uploadResult.failed)
        .map((item) => {
          const meta = item.meta;
          const data = item.data;

          return {
            size: data.size,
            originalName: meta.name,
            type: data.type,
            urlKey: String(meta.key),
          };
        });

      await createRedemptionForm({
        resource: 'deal/' + dealId + '/provide-redemption-statement',
        values: {
          ...values,
          fiKort: values.fiKort || false,
          registrationNumber: (values.registrationNumber + '')?.trim()?.toLowerCase(),
          accountNumber: (addTrailingZerosToStart(values.accountNumber + '', 10))?.trim()?.toLowerCase(),
          businessAccountId: values.fiKort ? null
            : values.businessAccountId === 'other' ? null
              : values.businessAccountId,
          files,
          redemptionDate: stringDateToISOString(values.redemptionDate),
        },
      });
      onComplete();
      return true;
    }}
  >
    <ProForm.Group
      align={'center'}
      style={{
        marginTop: 24,
        justifyContent: 'center',
      }}
    >

      <Flex
        style={{
          minWidth: '300px',
        }}
      >
        <ProFormSwitch
          fieldProps={{
            onChange: (e) => {
              setFiKort(e);
            },
          }}
          width={'lg'} name="fiKort" label={t('redemption.fiKort')} />
      </Flex>

      {!fiKort && <ProFormSelect
        width="lg"
        showSearch
        debounceTime={300}
        name="businessAccountId"
        request={async ({ keyWords }) => {
          const { data } = await dataProvider.custom<{
            data: BusinessAccount[];
          }>({
            url: 'symblepay-business-account/search' + (keyWords ? `?search=${keyWords}` : ''),
            method: 'get',
          });

          const result = data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          })) || [];

          result.push({
            label: 'Anden',
            value: 'other',
          });

          return result;
        }}
        label={t('redemption.bank')}
        placeholder="Danmarks Bank"
        rules={[
          { required: true, message: t('global.requiredField') },
        ]}
      />}

      <ProFormMoney
        width="lg"
        label={t('redemption.amount')}
        name="amount"
        locale={'da-DK'}
        fieldProps={{
          formatter: (value) =>
            new Intl.NumberFormat('da-DK', {
              style: 'currency',
              currency: 'DKK',
              minimumFractionDigits: 2,
            }).format(value || 0),
          parser: (value) =>
            value
              ? parseFloat(value.replace(/[^0-9,-]+/g, '').replace(',', '.'))
              : 0,
        }}
        placeholder={'0.0'}
        min={1}
        rules={[
          { required: true, message: t('global.requiredField') },
        ]}
      />

      {!fiKort && <ProFormText
        width="lg"
        name="registrationNumber"
        label={t('redemption.registrationNumber')}
        placeholder={'1234'}
        fieldProps={{
          maxLength: 4,
          minLength: 4,
          showCount: true,
          onKeyPress: allowOnlyNumbers,
        }}
        rules={[
          { required: true, message: t('global.requiredField') },
          {
            min: 4, message: t('global.minLength', { min: 4 }),
          },
        ]}
      />}

      {!fiKort && <ProFormText
        width="lg"
        name="accountNumber"
        label={t('redemption.accountNumber')}
        placeholder={'0012345678'}
        fieldProps={{
          maxLength: 10,
          showCount: true,
          onKeyPress: allowOnlyNumbers,
        }}
        rules={[
          { required: true, message: t('global.requiredField') },
        ]}
      />}

      <ProFormDatePicker
        name="redemptionDate"
        width="lg"
        label={t('redemption.redemptionDate', {
          takeoverDate: formatDate(takeoverDate),
        })}
        placeholder={'20-12-2024'}
        rules={[{ required: true, message: t('global.requiredField') }]}
        fieldProps={{
          format: 'DD-MM-YYYY',
          style: { background: 'white' },
          disabledDate: (current: dayjs.Dayjs | null): boolean => {
            return !!current && current.isBefore(dayjs(takeoverDate).add(1, 'day'));
          },
        }}
      />

      {!fiKort && <ProFormText
        width="lg"
        name="referenceNumber"
        label={t('redemption.referenceNumber')}
        placeholder="12345678"
        rules={[
          { required: true, message: t('global.requiredField') },
        ]}
      />}

      {fiKort && <Flex
        align={'center'}
        justify={'center'}
        wrap={false} vertical={false} gap={'small'}>
        <Text
          style={{
            paddingTop: 8,
            wordBreak: 'normal',
          }}
        >
          +71
        </Text>
        <ProFormText
          fieldProps={{
            style: { maxWidth: 216 },
          }}
          name="fiKortPaymentId"
          label={'Betalings-ID'}
          placeholder="Betalings-ID"
          rules={[
            { required: true, message: t('global.requiredField') },
          ]}
        />
        <ProFormText
          fieldProps={{
            style: { maxWidth: 216 },
          }}
          name="fiKortCreditor"
          label={'Kreditor'}
          placeholder="Kreditor"
          rules={[
            { required: true, message: t('global.requiredField') },
          ]}
        />

      </Flex>
      }

      <Form.Item
        style={{ width: 'calc(100vw - 72px)', maxWidth: 440 }}
        rules={[
          { required: true, message: t('global.requiredField') },
        ]}
        name={'files'}
        label={t('redemption.files')}
      >
        <FileUploader
          height={'200px'}
          restrictions={
            {
              maxFileSize: 20 * 1024 * 1024,
              maxNumberOfFiles: 3,
              allowedFileTypes: ['*/*'],
              minNumberOfFiles: 1,
            }
          }
          ref={fileUploader}
          orgOrDealId={dealId!}
          disabled={isLoading}
          autoSave={false}
          setFilesData={files => {
            form.setFieldValue('files', files);
            form.validateFields([name]);
          }}
        />
      </Form.Item>

    </ProForm.Group>
  </ModalForm>
    ;


};

const SecondOwnerActions = ({ deal, refetchDeal, seller }: {
  deal?: Deal,
  seller: boolean,
  refetchDeal: () => void
}) => {

  if (getStatusOrder(deal?.status) < getStatusOrder(DealStatus.SELL_AGREED)) {
    return <></>;
  }

  const { mutateAsync: recordNoOwner } = useCreate<SecondOwnerDetails>();

  const { confirm } = Modal;

  const details = seller ? deal?.secondSellerDetails : deal?.secondBuyerDetails;

  if (details === null || details === undefined) {
    return <Flex
      wrap={true}
      gap={'small'}
      justify={'flex-end'}

    >
      <Button
        type={'primary'}
        size={'middle'}
        onClick={() => {
          confirm({
            icon: <InfoCircleOutlined />,
            title: seller
              ? 'Ved at klikke på OK bekræfter du, at du er den eneste ejer af bilen.'
              : 'Ved at klikke på OK bekræfter du, at du skal fremgå som eneste ejer af bilen.',
            onOk() {
              return recordNoOwner({
                resource: 'deal/' + deal?.id + '/no-second-owner',
                values: {},
              }).then(refetchDeal);
            },
          });
        }}
      >
        Ingen sekundær ejer
      </Button>

      <SecondOwnerDetailsForm
        dealId={deal?.id}
        onComplete={refetchDeal}
      />
    </Flex>;

  }
};

const SecondOwnerDetailsForm = ({
                                  initial,
                                  dealId,
                                  onComplete = () => {
                                  },
                                }: { initial?: SecondOwnerDetails, dealId?: string, onComplete?: () => void }) => {
  const [form] = Form.useForm<SecondOwnerDetails>();

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createRedemptionForm } = useCreate<SecondOwnerDetails>({
    mutationOptions: {
      onSettled() {
        setIsLoading(false);
      },
    },
  });
  const { t } = useTranslation();

  return <ModalForm<SecondOwnerDetails>
    loading={isLoading}
    initialValues={initial}
    width={600}
    title={(initial ? t('global.edit') : t('global.add')) + ' ' + 'sekundær ejer'}
    trigger={
      <Button type="primary" size={'middle'}
              loading={isLoading}
              style={{
                minWidth: 142,
              }}
      >
        {initial ? t('global.edit') : t('global.add')}
      </Button>
    }
    form={form}
    autoFocusFirstInput
    modalProps={{
      destroyOnClose: true,
    }}
    onFinish={async (values) => {
      setIsLoading(true);
      await createRedemptionForm({
        resource: 'deal/' + dealId + '/provide-second-owner-detail',
        values: {
          ...values,
        },
      });
      onComplete();
      return true;
    }}
  >
    <ProForm.Group
      align={'center'}
      style={{
        marginTop: 24,
        justifyContent: 'center',
      }}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText
            name="firstName"
            label={t('common.user.firstName.label')}
            placeholder={t('common.user.firstName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ProFormText
            name="lastName"
            label={t('common.user.lastName.label')}
            placeholder={t('common.user.lastName.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12}>
          <ProFormText
            name="email"
            label={t('common.user.email.label')}
            placeholder={t('common.user.email.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />

        </Col>
        <Col xs={24} sm={12}>

          <ProFormText
            name="phoneNumber"
            label={t('common.user.phone.label')}
            placeholder={t('common.user.phone.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col xs={24} sm={8}>
          <ProFormText
            name="address"
            label={t('common.user.address.label')}
            placeholder={t('common.user.address.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormText
            name="city"
            label={t('common.user.addressCity.label')}
            placeholder={t('common.user.addressCity.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
        <Col xs={24} sm={8}>
          <ProFormDigit
            min={0}
            max={9999}
            fieldProps={{ precision: 0 }}
            name="zipCode"
            label={t('common.user.zipCode.label')}
            placeholder={t('common.user.zipCode.placeholder')}
            rules={[{ required: true, message: t('global.requiredField') }]}
          />
        </Col>
      </Row>

    </ProForm.Group>
  </ModalForm>;

};

const RedemptionStatementActions = ({ deal, seller, admin, refetchDeal }: {
  deal: Deal,
  refetchDeal: () => void,
  seller?: boolean,
  admin?: boolean
}) => {
  const { confirm } = Modal;
  const { t } = useTranslation();
  const { waitingForSeller, pendingApproval } = getRedemptionStatementState(deal);

  const {
    data: accDetails,
  } = useOne<{
    registrationNumber: string
    accountNumber: string
  }>({
    resource: 'symblepay-business-account',
    id: deal?.redemptionDetails?.businessAccountId,
    errorNotification: false,
    queryOptions: {
      enabled: admin && pendingApproval && !!deal?.redemptionDetails?.businessAccountId,
      retry: 0,
    },
  });

  const { Title } = Typography;

  const { mutateAsync: approveRedemptionStatement, isLoading } = useCreate();
  const { mutateAsync: changeBusinessAccount, isLoading: isChangingBusinessAccount } = useCreate();


  if (getStatusOrder(deal.status) < getStatusOrder(DealStatus.SELL_AGREED)) {
    return <></>;
  }


  if (seller && waitingForSeller) {
    return <RedemptionStatementForm takeoverDate={deal.takeOverDate} dealId={deal?.id} onComplete={refetchDeal} />;
  }

  if (
    admin
    && pendingApproval
  ) {
    const savedAccNumber = `${accDetails?.data?.registrationNumber}${accDetails?.data?.accountNumber}`;
    const redemptionDetails = deal.redemptionDetails;
    const accountNumberMatch = `${redemptionDetails?.registrationNumber}${redemptionDetails?.accountNumber}` === savedAccNumber;

    // if acc number not matched or no acc number saved
    if (((!accountNumberMatch && savedAccNumber) || !redemptionDetails?.businessAccountId) && redemptionDetails?.fiKort === false) {
      return <ModalForm
        trigger={
          <Button size={'middle'} loading={isChangingBusinessAccount} type={'primary'}>Bank matcher ikke -
            Rediger</Button>
        }
        submitter={{
          submitButtonProps: false,
          resetButtonProps: false,
          render: () => [],
        }}

        onFinish={async (values) => {
          changeBusinessAccount({
            resource: 'deal/' + deal?.id + '/change-business-account',
            values,
          }).then(refetchDeal);
          return true;
        }}
      >
        <Title level={1}>Vælg virksomheds konto</Title>

        <ProFormSelect
          width="lg"
          showSearch
          debounceTime={300}
          name="businessAccountId"
          request={async ({ keyWords }) => {
            const { data } = await dataProvider.custom<{
              data: BusinessAccount[];
            }>({
              url: 'symblepay-business-account/search' + (keyWords ? `?search=${keyWords}` : ''),
              method: 'get',
            });

            return data?.data?.map((item) => ({
              label: item.name,
              value: item.id,
            })) || [];
          }}
          label={t('redemption.bank')}
          placeholder="Danmarks Bank"
          rules={[
            { required: true, message: t('global.requiredField') },
          ]}
        />

        <Form.Item>
          <Flex justify={'flex-end'}>
            <Button size={'large'} loading={isChangingBusinessAccount} htmlType={'submit'}
                    type={'primary'}>Vælg</Button>
          </Flex>
        </Form.Item>

      </ModalForm>;
    }

    return <Flex wrap={true} gap={'small'}>

      <ModalForm
        trigger={
          <Button loading={isLoading} danger type={'primary'}>Afvis</Button>
        }
        loading={isLoading}
        submitter={{
          submitButtonProps: false,
          resetButtonProps: false,
          render: () => [],
        }}

        onFinish={async (values) => {
          return approveRedemptionStatement({
            resource: 'deal/' + deal?.id + '/approve-redemption',
            values: {
              ...values,
              approved: false,
            },
          }).then(refetchDeal);
        }}
      >
        <Title level={1}>Afvis indløsningserklæring</Title>

        <ProFormTextArea
          name="rejectionReason"
          label={t('redemption.rejectionReason')}
          placeholder="Angiv afvisningsgrund"
          rules={[{ required: true, message: t('global.requiredField') }]}
        />

        <Form.Item>
          <Flex justify={'flex-end'}>
            <Button size={'large'} loading={isLoading} htmlType={'submit'} type={'primary'}>Afvis</Button>
          </Flex>
        </Form.Item>

      </ModalForm>

      <Button
        type={'primary'}
        loading={isLoading}
        onClick={() => {
          confirm({
            title: 'Vil du godkende indfrielsesopgørelsen?',
            icon: <ExclamationCircleFilled />,
            content: 'Ved at klikke OK bekræfter du, at indfrielsesopgørelsen af korrekt og at det sælger har indtastet stemmer overens med det uploadede dokument.',
            onOk() {
              return approveRedemptionStatement({
                resource: 'deal/' + deal?.id + '/approve-redemption',
                values: {
                  approved: true,
                },
              }).then(refetchDeal);
            },
          });
        }}
      >
        Godkend
      </Button>
    </Flex>;
  }

  return <></>;

};


const getRedemptionStatementState = (deal?: Deal) => {
  const provided = !!deal?.redemptionDetails?.redemptionDate;
  const rejected = deal?.redemptionDetails?.approved === false;
  const approved = deal?.redemptionDetails?.approved === true;
  const pendingApproval = !!deal?.redemptionDetails && (deal?.redemptionDetails?.approved === null || deal?.redemptionDetails?.approved === undefined);
  const waitingForSeller = rejected || !provided;

  return { provided, rejected, approved, pendingApproval, waitingForSeller };
};

const getRedemptionStatementHintText = (deal?: Deal, seller?: boolean, admin?: boolean) => {
  const { provided, pendingApproval, waitingForSeller } = getRedemptionStatementState(deal);

  const baseTranslationPath = 'page.dealDetails.statusOptions.REDEMPTION_STATEMENT.' + (seller ? 'seller' : admin ? 'admin' : '');

  if (seller) {
    if (provided && pendingApproval) {
      return baseTranslationPath + '.waitingForAdminToApprove';
    }
  }

  if (admin && waitingForSeller) {
    return baseTranslationPath + '.waitingForSeller';
  }

  return '';

};

const redemptionStatementIcon = ({ deal, admin, seller, buyer }: {
  deal?: Deal,
  seller?: boolean,
  admin?: boolean,
  buyer?: boolean
}) => {


  if (getStatusOrder(deal?.status) < getStatusOrder(DealStatus.SELL_AGREED)) {
    return NotAvailableIcon;
  }

  const { provided, approved, pendingApproval, waitingForSeller } = getRedemptionStatementState(deal);

  if (approved) {
    return FinishedStepIcon;
  }

  if (seller) {
    if (provided && pendingApproval) {
      return WaitingIcon;
    }
    if (waitingForSeller) {
      return RequireActionIcon;
    }
  }

  if (buyer) {
    return WaitingIcon;
  }

  if (admin) {
    if (pendingApproval) {
      return RequireActionIcon;
    }

    if (waitingForSeller) {
      return WaitingIcon;
    }
  }


};

const secondOwnerDetailsIcon = ({ deal, admin, seller, buyer }: {
  deal?: Deal,
  seller?: boolean,
  admin?: boolean,
  buyer?: boolean
}) => {

  if (getStatusOrder(deal?.status) < getStatusOrder(DealStatus.SELL_AGREED)) {
    return NotAvailableIcon;
  }

  const secondSellerDetailsProvided = deal?.secondSellerDetails !== null && deal?.secondSellerDetails !== undefined;
  const secondBuyerDetailsProvided = deal?.secondBuyerDetails !== null && deal?.secondBuyerDetails !== undefined;

  if (seller) {
    if (secondSellerDetailsProvided) {
      return FinishedStepIcon;
    } else {
      return RequireActionIcon;
    }
  }

  if (buyer) {
    if (secondBuyerDetailsProvided) {
      return FinishedStepIcon;
    } else {
      return RequireActionIcon;
    }
  }

  if (admin) {
    if (secondSellerDetailsProvided && secondBuyerDetailsProvided) {
      return FinishedStepIcon;
    } else {
      return WaitingIcon;
    }
  }

};

const RedemptionStatementInfo = ({ deal }: { deal?: Deal, seller?: boolean, admin?: boolean }) => {
  const { t } = useTranslation();

  const { Text } = Typography;

  const details = deal?.redemptionDetails;

  return <Flex wrap={false} vertical={true} gap={'large'}>

    <Flex wrap={false} vertical={true} gap={'small'}>
      <Text>
        Da der er registreret pant i din bil, skal du indhente en indfrielsesopgørelse fra din långiver.
      </Text>

      <Text>
        Det er vigtigt at du angiver indfrielsesdatoen til mindst <b>1 dag efter den planlagte salgsdato</b>.
      </Text>

      <Text>
        Du skal derefter indsende indfrielsesopgørelsen til os, så vi kan sikre, at pantet bliver indfriet når handlen
        gennemføres.
      </Text>
      <Text>
        Indfrielsesopgørelsen indeholder information om:
      </Text>
      <ul>
        <li>
          Restbeløb
        </li>
        <li>Konto- og registreringsnummer hos långiver, hvor restbeløb skal indbetales</li>
        <li>Referencenummer, der skal tilføjes indbetalingen</li>
        <li>Indfrielsesdato</li>
      </ul>
      <Text>
        Indfrielsesdatoen er den seneste dato restbeløbet skal være indbetalt. Hvis datoen overskrides kan der være
        påløbet renter eller foretaget yderligere afdrag, hvilket påvirker restbeløbet.
      </Text>
      <Text>
        For at sikre, at långiver modtager indbetalingen af restbeløb i tide, skal indfrielsesdatoen være mindst 1
        dag <b>efter</b> den planlagte salgsdato.
      </Text>
      <Text italic>
        Hvis din långiver anvender indbetalingskort til indfrielse skal du vælger ‘Indbetalingskort’ i formularen.
      </Text>

    </Flex>

    <ProDescriptions
      loading={!deal}
      column={1}
      title={t('redemption.title')}
    >

      <ProDescriptions.Item
        label={t('redemption.fiKort')}
        valueType="text"
      >
        {details?.fiKort ? t('global.yes') : t('global.no')}
      </ProDescriptions.Item>

      {!details?.fiKort && <ProDescriptions.Item
        label={t('redemption.accountNumber')}
        valueType="text"
      >
        {details?.accountNumber || t('global.notAvailable')}
      </ProDescriptions.Item>}


      {details?.fiKort && <ProDescriptions.Item
        label={t('redemption.fiKort')}
        valueType="text"
      >
        +71 {details?.fiKortCreditor || t('global.notAvailable')} {details?.fiKortPaymentId || t('global.notAvailable')}
      </ProDescriptions.Item>}


      <ProDescriptions.Item
        label={t('redemption.amount')}
        valueType="text"
      >
        DKK {details?.amount?.toLocaleString('da-DK', {
        minimumFractionDigits: 2,
      }) || t('global.notAvailable')}
      </ProDescriptions.Item>
      <ProDescriptions.Item
        label={'Indfrielsesdato'}
        valueType="text"
      >
        {formatDate(details?.redemptionDate)}
      </ProDescriptions.Item>
      {!details?.fiKort && <ProDescriptions.Item
        label={t('redemption.referenceNumber')}
        valueType="text"
      >
        {details?.referenceNumber || t('global.notAvailable')}
      </ProDescriptions.Item>}
      <ProDescriptions.Item
        label={t('redemption.approved')}
        valueType="text"
      >
        {details?.approved === true ? t('global.approved') : details?.approved === false ? t('global.rejected') : !!details && (details.approved === null || details.approved === undefined) ? t('global.waiting') : t('global.notYet')}
      </ProDescriptions.Item>

      {details?.rejectionReason
        && <ProDescriptions.Item
          label={t('redemption.rejectionReason')}
          valueType="text"
        >
          {details?.rejectionReason || t('global.notAvailable')}
        </ProDescriptions.Item>}

      <ProDescriptions.Item
        label={'Vedhæftede filer'}
        valueType={'formList'}
      >
        <Flex wrap={false} vertical={true} gap={'small'} align={'start'}>
          {(details?.files || []).map((item, idx) => {
            return <Flex key={idx} gap={'small'} align={'start'}>
              <Typography.Text><FileOutlined /></Typography.Text> {item.originalName}
              <DefaultButton type="link"
                             size={'small'}
                             icon={<DownloadOutlined />}
                             href={`${dataProvider.getApiUrl()}/file/dealer-car/download-file/${item?.urlKey}`}
                             target="_blank"></DefaultButton>
            </Flex>;
          })}
        </Flex>
      </ProDescriptions.Item>
    </ProDescriptions>
  </Flex>;

};

const SecondOwnerDetailsInfo = ({
                                  details,
                                  dealId,
                                  title,
                                  allowEdit = false,
                                  onComplete = () => {
                                  },
                                }: {
  dealId?: string,
  details?: SecondOwnerDetails,
  title: string,
  allowEdit: boolean,
  onComplete: () => void,
}) => {
  const { t } = useTranslation();

  const detailsNotProvided = details === null || details === undefined;
  const noSecondOwner = !detailsNotProvided && !details?.email;

  return <Flex wrap={false} vertical={true} gap={'large'}>
    <ProDescriptions
      loading={!details}
      column={1}
      title={title}
    >
      {noSecondOwner && <ProDescriptions.Item>
        Ingen sekundær ejer
      </ProDescriptions.Item>}
      {!noSecondOwner &&
        <>
          <ProDescriptions.Item
            label={t('common.user.firstName.label')}
            valueType="text"
          >
            {details?.firstName || t('global.notAvailable')}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            label={t('common.user.lastName.label')}
            valueType="text"
          >
            {details?.lastName || t('global.notAvailable')}
          </ProDescriptions.Item>

          <ProDescriptions.Item
            label={t('common.user.email.label')}
            valueType="text"
          >
            {details?.email || t('global.notAvailable')}
          </ProDescriptions.Item>

          <ProDescriptions.Item
            label={t('common.user.address.label')}
            valueType="text"
          >
            {details?.address || t('global.notAvailable')}
          </ProDescriptions.Item>

          <ProDescriptions.Item
            label={t('common.user.zipCode.label')}
            valueType="text"
          >
            {details?.zipCode || t('global.notAvailable')}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            label={t('common.user.zipCode.label')}
            valueType="text"
          >
            {details?.city || t('global.notAvailable')}
          </ProDescriptions.Item>
        </>
      }


      {allowEdit && <ProDescriptions.Item>
        <SecondOwnerDetailsForm
          dealId={dealId}
          initial={details}
          onComplete={onComplete}
        >
        </SecondOwnerDetailsForm>
      </ProDescriptions.Item>}

    </ProDescriptions>

  </Flex>;

};

const SignContractInfo = ({ details, title }: { title: string, details?: UserToSignDetails }) => {
  if (!details) {
    return <></>;
  }

  return <ProDescriptions
    column={1}
    title={title}
  >
    <ProDescriptions.Item
      label={'Status for underskrift'}
      valueType={'group'}
      contentStyle={{ display: 'flex', alignItems: 'center' }}
    >
      {details?.completed &&
        <Tooltip title="underskrevet"> <CheckCircleTwoTone style={{
          fontSize: 16,
        }} twoToneColor={'green'} />
        </Tooltip>}
      {details?.rejected &&
        <Tooltip title="afvist"><StopTwoTone twoToneColor={'red'} style={{
          fontSize: 16,
        }} />
        </Tooltip>}
      {!details?.rejected && !details?.completed &&
        <Tooltip title={'venter'}><ClockCircleOutlined style={{
          fontSize: 16,
        }} /></Tooltip>}
    </ProDescriptions.Item>
  </ProDescriptions>;

};


const addDashPrefixIfNotEmpty = (str: string | undefined) => {
  return str ? ` - ${str}` : '';
};

const PaymentStageInfo = ({ deal, seller, buyer, admin }: {
  deal?: Deal,
  seller?: boolean,
  buyer?: boolean,
  admin?: boolean
}) => {
  const { mutateAsync, isLoading } = useCreate<SymblePayRedirect>();
  const { mutateAsync: assignPayoutAccount, isLoading: isLoadingPayoutAccount } = useCreate<SymblePayRedirect>();

  const { Text } = Typography;

  const { t } = useTranslation();

  const accountDetails = seller ? deal?.sellerSymblePayAccDetails : deal?.buyerSymblePayAccDetails;

  const tradeStarted = !!deal?.tradeSymblePayDetails;


  return <Flex wrap={false} vertical={true} gap={'large'}>

    {!admin && seller && <Flex wrap={false} vertical={true} gap={'small'}>
      <Text>
        Selve betalingen går gennem <Link href={'https://safepaynordic.dk/'} target={'_blank'}>Safepay</Link>.
      </Text>
      <Text>
        Safepay er en sikker dansk escrow-løsning, med tilladelse fra Finanstilsynet, der garanterer både dig og køber
        en sikkert og trygt salg.
      </Text>

      <Text>
        Med Safepay indbetaler køber pengene på en særlig sikringskonto, <b>inden</b> du overdrager bilen og laver
        ejerskiftet.
      </Text>
      <Text>
        Når pengene står på sikringskontoen kan køber ikke længere tilgå dem, og <b>du er garanteret betalingen</b>, så
        snart du har bekræftet, at bilen er overdraget til køber. Du vil have pengene på din konto den næste bankdag.
      </Text>
      <Text>
        Hvis du af den ene eller anden grund vælger ikke at overdrage bilen til køber, annulleres betalingen og pengene
        bliver tilbageført til køber. Køber får dermed sikkerhed for at betalingen kun gennemføres såfremt bilen
        overdrages og ejerskiftes.
      </Text>
      <Text>Yderligere fordele ved Safepay:</Text>
      <ul>
        <li>Oprettelse for både sælger og køber sker med MitID, hvilket sikrer identifikation og forhindrer svindel.
        </li>
        <li>Har du pant i bilen, bliver dette automatisk betalt til långiver når handlen gennemføres.</li>
        <li>Salæret til Aksel modregnes automatisk, så du ikke skal bekymre dig om det efterfølgende.</li>
      </ul>
      <Text>
        Hvis salgsprisen overstiger <b>250.000 kr</b>. skal du hæve din beløbsgrænse hos Safepay forud for udbetalingen.
        Det kan kræve indsendelse af pas. Det er en del af de lovpligtige KYC-regler (Know Your Customer).
      </Text>
      <Text>
        Safepay tager et selvstændigt sælgerbeskyttelsesgebyr på 0,25% (min. 375 kr.) af transaktionen for at facilitere
        det sikre
        salg. Gebyret opkræves uafhængigt af Aksel og Aksel har ingen indflydelse på det.
      </Text>
    </Flex>}
    {!admin && buyer && <Flex wrap={false} vertical={true} gap={'small'}>
      <Text>
        Selve betalingen går gennem <Link href={'https://safepaynordic.dk/'} target={'_blank'}>Safepay</Link>.
      </Text>
      <Text>
        Safepay er en sikker dansk escrow-løsning, med tilladelse fra Finanstilsynet, der garanterer både dig og sælger
        en sikker og tryg handel.
      </Text>

      <Text>
        Med Safepay indbetaler du pengene på en særlig sikringskonto, hvorfra de først bliver overført til
        sælger <b>efter</b> du har fået overdraget bilen og der er lavet officielt ejerskifte.
      </Text>
      <Text>
        Først når du har bekræftet, at bilen er ejerskiftet til dit navn, bliver pengene frigivet til sælger.
      </Text>
      <Text>
        Hvis du af den ene eller anden grund ikke får overdraget og ejerskiftet bilen, annulleres betalingen og pengene
        bliver tilbageført til din konto.
      </Text>

      <Text>Du er dermed 100% sikret når du overfører pengene og er garanteret, at du kun betaler, hvis du rent faktisk
        får bilen.</Text>

      <Text>Yderligere fordele ved Safepay:</Text>
      <ul>
        <li>Oprettelse for både sælger og køber sker med MitID, hvilket sikrer identifikation og forhindrer svindel.
        </li>
        <li>Er der pant i bilen, bliver dette automatisk betalt til långiver når handlen gennemføres, så du er sikker
          på, at bilen er pantfri.
        </li>
      </ul>

      <Text>
        Safepay tager et selvstændigt køberbeskyttelsesgebyr på 0,25% (min. 375 kr.) af transaktionen for at facilitere
        det sikre salg.
        Gebyret opkræves uafhængigt af Aksel og Aksel har ingen indflydelse på det.
      </Text>

    </Flex>}

    <ProDescriptions
      loading={!deal}
      column={1}
      title={t('symblepay.symblePayAccountInformationTitle')}
    >
      <ProDescriptions.Item
        label={t('symblepay.userNameLabel')}
        valueType="text"
      >
        {accountDetails?.name || t('global.notAvailable')}
      </ProDescriptions.Item>
      {
        accountDetails && !admin && !tradeStarted &&
        <ProDescriptions.Item><Button loading={isLoading} type="link" onClick={async () => {
          const response = await mutateAsync({
            successNotification: () => false,
            resource: `symble-pay/${deal?.id}/connect`,
            values: {},
          });

          response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
        }}>{t('symblepay.selectAnotherAccountButton')}</Button></ProDescriptions.Item>
      }

    </ProDescriptions>

    <ProDescriptions
      loading={!deal}
      column={1}
      title={
        (seller ? 'Sælger ' : 'Køber ') +
        t('symblepay.symblePayBankAccountDetails')
      }
    >
      <ProDescriptions.Item
        label={t('symblepay.bankNameLabel')}
        valueType="text"
      >
        {accountDetails?.bankAccount?.bank || t('global.notAvailable')}
      </ProDescriptions.Item>
      <ProDescriptions.Item
        label={t('symblepay.accountNameLabel')}
        valueType="text"
      >
        {accountDetails?.bankAccount?.name || t('global.notAvailable')}
      </ProDescriptions.Item>

      <ProDescriptions.Item
        label={t('symblepay.accountNumberLabel')}
        valueType="text"
      >
        {accountDetails?.bankAccount?.accountNumber || t('global.notAvailable')}
      </ProDescriptions.Item>

      {
        accountDetails?.bankAccount && !tradeStarted && !admin &&
        <ProDescriptions.Item>
          <Button loading={isLoadingPayoutAccount}
                  type="link"
                  onClick={async () => {
                    const response = await assignPayoutAccount({
                      successNotification: () => false,
                      resource: `symble-pay/${deal?.id}/select-payout-account`,
                      values: {},
                    });
                    response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
                  }}>{t('symblepay.selectAnotherPayoutAccountButton')}</Button>
        </ProDescriptions.Item>
      }
    </ProDescriptions>
  </Flex>;

};


const PaymentStageTradeDetails = ({ deal, showPayeesDetails }: { deal?: Deal, showPayeesDetails: boolean, }) => {
  const { t } = useTranslation();
  const tradeDetails = deal?.tradeSymblePayDetails;


  return !!tradeDetails && <Flex wrap={false} vertical={true} gap={'large'}>
    <ProDescriptions
      loading={!deal}
      column={1}
      title={'Transaktionsoplysninger'}
    >
      <ProDescriptions.Item
        label={'Titel'}
        valueType="text"
      >
        {tradeDetails?.title || t('global.notAvailable')}
      </ProDescriptions.Item>
      <ProDescriptions.Item
        label={'Beskrivelse'}
        valueType="text"
      >
        {tradeDetails?.description || t('global.notAvailable')}
      </ProDescriptions.Item>

      <ProDescriptions.Item
        label={'Beløb'}
        fieldProps={{
          customSymbol: 'DKK ',
        }}
        valueType="text"
      >
        DKK {tradeDetails?.price?.toLocaleString('da-DK', {
        minimumFractionDigits: 2,
      }) || t('global.notAvailable')}
      </ProDescriptions.Item>
    </ProDescriptions>

    {
      showPayeesDetails && (tradeDetails?.prioritizedPayees || []).map((payee, idx) => {
        return <ProDescriptions
          loading={!deal}
          key={idx}
          column={1}
          title={payee.description}
        >
          <ProDescriptions.Item
            label={'Bankreferencetekst'}
            valueType="text"
          >
            {payee.bankReferenceText || t('global.notAvailable')}
          </ProDescriptions.Item>
          <ProDescriptions.Item
            label={'Beløb'}
            valueType="text"
            fieldProps={{
              customSymbol: '',
            }}
          >
            DKK -{payee.amount.toLocaleString('da-DK', {
            minimumFractionDigits: 2,
          })}
          </ProDescriptions.Item>
        </ProDescriptions>;
      })
    }
    {
      showPayeesDetails && tradeDetails?.payee?.fees && <ProDescriptions
        loading={!deal}
        title={'Safepay gebyr'}
        column={1}
      >
        <ProDescriptions.Item
          label={'Transaktionsgebyr'}
          valueType="text"
          fieldProps={{
            customSymbol: '',
          }}
        >
          DKK -{tradeDetails.payee.fees.amount.toLocaleString('da-DK', {
          minimumFractionDigits: 2,
        })}
        </ProDescriptions.Item>
      </ProDescriptions>
    }
    {
      showPayeesDetails && tradeDetails?.payee?.fees && <ProDescriptions
        loading={!deal}
        title={'Udbetalt til konto'}
        column={1}
      >
        <ProDescriptions.Item
          label={'Beløb'}
          valueType="text"
          fieldProps={{
            customSymbol: '',
          }}
        >
          DKK {(tradeDetails.price
          - tradeDetails.payee.fees.amount
          - (tradeDetails?.prioritizedPayees || []).reduce((acc, payee) => acc + payee.amount, 0)).toLocaleString('da-DK', {
          minimumFractionDigits: 2,
        })}
        </ProDescriptions.Item>
      </ProDescriptions>
    }

  </Flex>;

};


const ConfirmOwnershipChangeDetails = ({ seller, buyer }: { seller: boolean, buyer: boolean }) => {
  const { Text } = Typography;

  return <Flex wrap={false} vertical={true} gap={'large'}>

    {
      seller && <>

        <Text>
          Køber har nu indbetalt pengene til sikringskontoen hos Safepay. Du er dermed garanteret betalingen når
          ejerskiftet gennemføres.
        </Text>
        <Text>For at foretage ejerskiftet skal du: </Text>

        <ul>
          <li>Åbne Ejerskifte app’en</li>
          <li>Vælge bilen fra oversigten og trykke ‘Start omregistrering’</li>
          <li>Der kommer en 6 cifret kode, som du skal give til køber.</li>
          <li>Når køber indtaster den 6 cifrede kode i sin Ejerskifte app, omregistreres bilen til køber.</li>
          <li>Når ejerskiftet er gennemført skal du bekræfte ovenfor.</li>
          <li>Pengene vil derefter blive frigivet til dig fra sikringskontoen og du vil have dem på din konto næste
            bankdag.
          </li>
        </ul>
        <Text>Hvis I oplever problemer med Ejerskifte app’en, har vi her lavet en <Link target={'_blank'}
                                                                                        href={ChangeOwnershipGuide}>guide
          til at lave ejerskiftet via motorstyrelsens hjemmeside</Link></Text>

      </>
    }
    {
      buyer && <>
        <Text>
          Pengene er nu indbetalt på sikringskontoen hos Safepay. Når ejerskiftet er foretaget og du har bekræftet det,
          vil de blive frigivet til sælger.
        </Text>
        <Text>For at foretage ejerskiftet skal du: </Text>

        <ul>
          <li>Åbne Ejerskifte app’en.</li>
          <li>Sælger vælger ‘Start omregistrering’ i sin app.</li>
          <li>Der kommer en 6 cifret kode, som du skal bruge for at låse bilen op</li>
          <li>Du vælger ‘Tilføj køretøj’ og indtaster koden i din app.</li>
          <li>Følg vejledning i app’en, så bliver bilen ejerskiftet til dig.</li>
          <li>Når ejerskiftet er gennemført skal du bekræfte ovenfor. <Text italic>Sælger skal først bekræfte ejerskiftet, før du kan bekræfte.</Text>
          </li>
          <li>Pengene vil derefter blive frigivet til sælger.</li>
        </ul>
        <Text>Hvis I oplever problemer med Ejerskifte app’en, har vi her lavet en <Link target={'_blank'}
                                                                                        href={ChangeOwnershipGuide}>guide
          til at lave ejerskiftet via motorstyrelsens hjemmeside</Link></Text>

      </>
    }


  </Flex>;
};


function getHintMessageForPaymentStage(deal: Deal, seller: boolean, buyer: boolean, admin: boolean) {

  const order = getStatusOrder(deal?.status);

  if (order !== getStatusOrder(DealStatus.PAYMENT)) {
    return '';
  }
  const {
    sellerSymblePayAccDetails,
    buyerSymblePayAccDetails,
    tradeSymblePayDetails,
    sellerConfirmedCarOwnershipTransfer,
  } = deal;

  const requireConnectAcc = seller && !sellerSymblePayAccDetails || (buyer && !buyerSymblePayAccDetails);
  const requireSelectPayoutAcc = seller && !sellerSymblePayAccDetails?.bankAccount || (buyer && !buyerSymblePayAccDetails?.bankAccount);

  if (!requireConnectAcc && !requireSelectPayoutAcc) {
    if (seller || admin) {
      if (!tradeSymblePayDetails) {
        return 'Afventer at køber indbetaler pengene til sikringskontoen hos Safepay';
      } else {
        return 'Pengene er indbetalt til sikringskonto hos Safepay';
      }
    }

    if (buyer || admin) {
      if (tradeSymblePayDetails && !sellerConfirmedCarOwnershipTransfer) {
        return 'Venter på at køber indbetaler pengene til sikringskontoen hos Safepay';
      }
    }
  }
}

const ConnectSymblePayStageExtra = ({ deal }: { deal: Deal, refetchDeal: () => void }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayoutAccount, setIsLoadingPayoutAccount] = useState(false);

  const { mutateAsync } = useCreate<SymblePayRedirect>({});
  const { mutateAsync: assignPayoutAccount } = useCreate<SymblePayRedirect>();

  const { user, isAdmin } = useAuthContext();

  const { t } = useTranslation();

  const {
    id,
    status,
    sellerSymblePayAccDetails,
    buyerSymblePayAccDetails,
    sellerId,
    buyerId,
  } = deal;

  const seller = sellerId === user?.clientId;
  const buyer = buyerId === user?.clientId;

  const order = getStatusOrder(status);
  const requireConnectAcc = seller && !sellerSymblePayAccDetails || (buyer && !buyerSymblePayAccDetails);
  const requireSelectPayoutAcc = seller && !sellerSymblePayAccDetails?.bankAccount || (buyer && !buyerSymblePayAccDetails?.bankAccount);

  if (order < getStatusOrder(DealStatus.SELL_AGREED)) {
    return NotAvailableIcon;
  } else if (
    (!isAdmin && !requireConnectAcc && !requireSelectPayoutAcc)
    || (
      isAdmin && sellerSymblePayAccDetails && buyerSymblePayAccDetails
      && ((sellerSymblePayAccDetails as unknown as SymblePayAccountDetails)?.bankAccount)
      && ((buyerSymblePayAccDetails as unknown as SymblePayAccountDetails)?.bankAccount)
    )
  ) {
    return FinishedStepIcon;
  } else {

    if (seller && sellerSymblePayAccDetails && sellerSymblePayAccDetails?.bankAccount) {
      return WaitingIcon;
    }

    if (buyer && buyerSymblePayAccDetails && buyerSymblePayAccDetails?.bankAccount) {
      return WaitingIcon;
    }

    const connectButton = <>
      <Button loading={isLoading} type="primary" onClick={async () => {
        setIsLoading(true);
        try {
          const response = await mutateAsync({
            successNotification: () => false,
            resource: `symble-pay/${id}/connect`,
            values: {},
          });

          response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
        } catch (e) {
          setIsLoading(false);
        }

      }}>{t('symblepay.connectAccountButton')}</Button>
    </>;


    const selectPayoutAccountButton = <>
      <Button loading={isLoadingPayoutAccount} type="primary" onClick={async () => {
        setIsLoadingPayoutAccount(true);
        try {
          const response = await assignPayoutAccount({
            successNotification: () => false,
            resource: `symble-pay/${id}/select-payout-account`,
            values: {},
          });

          response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
        } catch (e) {
          // loading state only in error is expected to do not allow person to click it again
          setIsLoading(false);
        }

      }}>{t('symblepay.selectPayoutAccountButton')}</Button>
    </>;

    return <Flex style={{}} gap={'small'} align={'center'} justify={'space-between'}>
      <Flex wrap={true} style={{}} gap={'small'} align={'center'}>
        {!isAdmin && requireConnectAcc && connectButton}{!requireConnectAcc && requireSelectPayoutAcc && selectPayoutAccountButton}
      </Flex>
      {!isAdmin && !requireConnectAcc && !requireSelectPayoutAcc && WaitingIcon}
      {!isAdmin && (requireConnectAcc || requireSelectPayoutAcc) && RequireActionIcon}

      {isAdmin && WaitingIcon}

    </Flex>;
  }
};

const TradeStageExtra = ({ deal, refetchDeal }: { deal: Deal, refetchDeal: () => void }) => {
  const { mutateAsync: initiateTrade, isLoading: isInitiatingTrade } = useCreate<SymblePayRedirect>();
  const { user, isAdmin } = useAuthContext();
  const { confirm } = Modal;
  const { Text } = Typography;
  const {
    id,
    status,
    tradeSymblePayDetails,
    sellerSymblePayAccDetails,
    buyerSymblePayAccDetails,
    sellerId,
    buyerId,
  } = deal;

  const seller = sellerId === user?.clientId;
  const buyer = buyerId === user?.clientId;

  const order = getStatusOrder(status);

  if (order > getStatusOrder(DealStatus.PAYMENT)) {
    return FinishedStepIcon;
  } else if (order < getStatusOrder(DealStatus.PAYMENT)) {
    return NotAvailableIcon;
  } else if (status === DealStatus.PAYMENT) {
    const requireConnectAcc = (seller && !sellerSymblePayAccDetails) || (buyer && !buyerSymblePayAccDetails);
    const requireSelectPayoutAcc = (seller && !sellerSymblePayAccDetails?.bankAccount) || (buyer && !buyerSymblePayAccDetails?.bankAccount);

    if (requireConnectAcc || requireSelectPayoutAcc) {
      return WaitingIcon;
    }

    if (buyer) {

      if (!sellerSymblePayAccDetails || !sellerSymblePayAccDetails?.bankAccount) {
        return WaitingIcon;
      }

      if (!tradeSymblePayDetails && sellerSymblePayAccDetails?.bankAccount && buyerSymblePayAccDetails && sellerSymblePayAccDetails && buyerSymblePayAccDetails?.bankAccount) {
        return <Flex gap={8}>
          <Button loading={isInitiatingTrade} type="primary" onClick={async () => {
            async function startTransaction() {
              const response = await initiateTrade({
                successNotification: () => false,
                resource: `symble-pay/${id}/initiate-trade`,
                values: {},
              });

              response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
              refetchDeal();
            }

            if (deal?.transactions?.length > 2) {
              confirm({
                icon: <WarningTwoTone twoToneColor={'orange'} />,
                content: <div>
                  <Text>Hvis du har gennemført betalingen og pengene er trukket fra din konto, men det ikke fremgår her,
                    skyldes det formentlig, at din bank tilbageholder pengene for at lave et sikkerheds- og
                    hvidvasktjek. Der kan forekomme stikprøvekontrol ved beløb af denne størrelse.</Text>
                  <div />
                  <Text> Det tager ofte mellem 10 min og 1 time før de frigives. Vi anbefaler, at du ringer til din bank
                    for at høre nærmere.</Text>
                  <div />
                  <Text> Så snart pengene er indbetalt på sikringskontoen hos Safepay vil det fremgå af denne side og
                    handlen vil kunne fortsætte.</Text>
                </div>,
                title: 'Gå til indbetaling?',
                okText: 'Gå til indbetaling',
                cancelText: 'Tilbage',
                onOk() {
                  startTransaction();
                },
              });

            } else {
              await startTransaction();
            }
          }}>Indbetal til Safepay sikringskonto</Button>
          <>{RequireActionIcon}</>
        </Flex>;
      }

    }

    if (seller || isAdmin) {
      return WaitingIcon;
    }
  }

};

const ConfirmOwnershipChangeStageExtra = ({ deal, refetchDeal }: { deal: Deal, refetchDeal: () => void }) => {
  const { mutateAsync: confirmOwnershipTransfer, isLoading: isConfirmingOwnershipTransfer } = useCreate();
  const { confirm } = Modal;

  const { user, isAdmin } = useAuthContext();

  const {
    isLoading: isAcceptingTrade,
    mutateAsync: acceptTrade,
  } = useCreate<FileUpload>({});

  const {
    id,
    status,
    tradeSymblePayDetails,
    sellerConfirmedCarOwnershipTransfer,
    sellerId,
    buyerId,
  } = deal;

  const seller = sellerId === user?.clientId;
  const buyer = buyerId === user?.clientId;

  const order = getStatusOrder(status);

  if (order > getStatusOrder(DealStatus.CONFIRM_OWNERSHIP_CHANGE)) {
    return FinishedStepIcon;
  } else if (order < getStatusOrder(DealStatus.CONFIRM_OWNERSHIP_CHANGE)) {
    return NotAvailableIcon;
  } else if (status === DealStatus.CONFIRM_OWNERSHIP_CHANGE) {
    if (buyer) {
      if (!sellerConfirmedCarOwnershipTransfer) {
        return WaitingIcon;
      }

      if (tradeSymblePayDetails && sellerConfirmedCarOwnershipTransfer) {
        return <Flex style={{}} gap={8}>
          <Button
            type={'primary'}
            loading={isAcceptingTrade}
            onClick={() => {
              confirm({
                icon: <InfoCircleOutlined />,
                title: 'Ved at klikke OK bekræfter du, at bilen er ejerskiftet til dig',
                onOk() {

                  return acceptTrade({
                    resource: 'deal/' + id + '/accept-trade',
                    values: {},
                  }).then(() => refetchDeal());
                },
              });
            }}>
            Bekræft ejerskifte
          </Button>
          {RequireActionIcon}
        </Flex>;
      }
    }

    if (seller) {
      if (tradeSymblePayDetails && !sellerConfirmedCarOwnershipTransfer) {
        return <Flex style={{}} gap={8}>
          <Button
            type={'primary'}
            loading={isConfirmingOwnershipTransfer}
            onClick={() => {
              confirm({
                icon: <InfoCircleOutlined />,
                title: 'Ved at klikke på OK bekræfter du, at bilen er ejerskiftet til køber',
                onOk() {
                  return confirmOwnershipTransfer({
                    resource: 'deal/' + deal?.id + '/confirm-ownership-transfer',
                    values: {},
                  }).then(refetchDeal);
                },
              });
            }}>
            Bekræft ejerskifte
          </Button>
          {RequireActionIcon}
        </Flex>;
      }
      return WaitingIcon;
    }

    if (isAdmin) {
      return WaitingIcon;
    }
  }

};


const SignContractStageExtra = ({
                                  deal,
                                  isAdmin,
                                  seller,
                                  refetchDeal,
                                }: {
  deal?: Deal,
  isAdmin: boolean,
  seller: boolean,
  refetchDeal: () => Promise<unknown>
}) => {

  const { mutateAsync: resendContract, isLoading: isResendingContract } = useCreate<Deal>();

  const { confirm } = Modal;

  const [downloadingFile, setDownloadingFile] = useState(false);

  const downloadContract = async () => {
    setDownloadingFile(true);
    try {
      const response = await axiosInstance.request<Blob>(
        {
          url: `${API_URL}/deal/${deal?.id}/download-contract`,
          method: 'GET',
          responseType: 'arraybuffer',
        },
      );

      saveAs(new Blob([response.data], {
        type: 'application/pdf',
      }), `contract-${deal?.getCaradsId}.pdf`);

    } finally {
      setDownloadingFile(false);
    }

  };

  const contractInfo = deal?.penneoSalesContractSignInformation;
  const firstOwnerDetails = seller ? contractInfo?.firstSeller : contractInfo?.firstBuyer;
  const secondOwnerDetails = seller ? contractInfo?.secondSeller : contractInfo?.secondBuyer;

  const hasAtLeastOneReject = [
      contractInfo?.firstSeller,
      contractInfo?.firstBuyer,
      contractInfo?.secondSeller,
      contractInfo?.secondBuyer,
    ].filter(c => c).some(c => c?.rejected)
    || contractInfo?.rejected;


  if (isAdmin && !deal?.tradeSymblePayDetails) {
    return <Flex gap={'small'} wrap={false}>
      <Flex gap={'small'} wrap={true} justify={'flex-end'}>
        <Button type="primary" onClick={downloadContract} loading={downloadingFile}>Download kontrakt</Button>
        <Button
          type={'primary'}
          size={'middle'}
          loading={isResendingContract}
          onClick={() => {
            confirm({
              icon: <InfoCircleOutlined />,
              title: 'Når du gensender kontrakten vil den tidligere kontrakt bliver slettet og alle parter vil skulle underskrive på ny.',
              onOk() {
                return resendContract({
                  resource: 'deal/' + deal?.id + '/initiate-contract',
                  values: {},
                }).then(refetchDeal);
              },
            });
          }}
        >
          Gensend kontrakt
        </Button>
      </Flex>

      <SignContractStageIcon deal={deal} seller={seller} isAdmin={isAdmin}></SignContractStageIcon>
    </Flex>;
  }

  return <Flex style={{}} gap={'small'} wrap={false}>
    {!hasAtLeastOneReject && <Flex gap={'small'} wrap={true}>

      {!isAdmin && firstOwnerDetails?.completed === false &&
        <Button type="primary" href={firstOwnerDetails?.url} target={'_blank'}>
          Læs og underskriv slutseddel
        </Button>}

      {!isAdmin && secondOwnerDetails?.completed === false && <Button
        type="primary" href={secondOwnerDetails?.url} target={'_blank'}
      >
        Link til sekundær ejer
      </Button>}
    </Flex>}

    <SignContractStageIcon deal={deal} seller={seller} isAdmin={isAdmin}></SignContractStageIcon>
  </Flex>;

};


const CarSoldBuyerInfo = () => {
  const { Title, Text } = Typography;

  return <Flex style={{}} gap={'small'} wrap={false} vertical={true}>
    <Title level={3}>Tillykke med din nye bil!</Title>
    <Text>Handlen er nu gennemført og du har fået en ny bil. </Text>
    <Text>Husk at få nøgler, papirer og evt. dæk og andet udstyr, der indgår i handlen.</Text>
    <Text>Vi håber du bliver glad for bilen!</Text>
  </Flex>;
};

const CarSoldSellerInfo = () => {
  const { Title, Text } = Typography;

  return <Flex style={{}} gap={'small'} wrap={false} vertical={true}>
    <Title level={3}>Tillykke! Du har nu solgt din bil</Title>
    <Text>Husk at aflevere nøgler, papirer og evt. dæk og andet udstyr, der indgår i handlen.</Text>
    <Text>Du har pengene på din konto næste bankdag.</Text>
    <Text>Tak fordi vi måtte hjælpe dig med at sælge din bil.</Text>
  </Flex>;
};

const ShowingToBuyerInformation = ({ status, dealId }: { status: DealStatus, dealId: string }) => {
  const { data } = useCustom<BuyerSubmissionPendingInfo>({
    url: `buyer-submission/${dealId}/current-buyer-latest-pending-submission`,
    method: 'get',
    errorNotification: false,
    queryOptions: {
      enabled: status === DealStatus.SHOWING_TO_BUYER,
      retry: 0,
    },
  });

  const { Title, Text } = Typography;

  const buyerSubmission = data?.data;

  return <Flex style={{}} gap={'small'} wrap={false} vertical={true}>
    {
      buyerSubmission && <ProDescriptions
        column={1}
        title={'Fremvisning booket'}
      >
        <ProDescriptions.Item
          label={'Dato'}
          valueType="text">
          {formatDate(buyerSubmission.meetTime)}
        </ProDescriptions.Item>
        <ProDescriptions.Item
          label={'Tid'}
          valueType="text">
          {
            new Intl.DateTimeFormat('en-GB', {
              timeStyle: 'short',
              timeZone: 'CET',
            }).format(new Date(buyerSubmission.meetTime))
          }
        </ProDescriptions.Item>
        <ProDescriptions.Item
          label={'Sted'}
          valueType="text">
          {buyerSubmission.address}
        </ProDescriptions.Item>
      </ProDescriptions>
    }
    {
      buyerSubmission && <Text>
        Det er {buyerSubmission.name}, der er interesseret i din bil.
      </Text>
    }
    <Text italic>
      Hvis du har pant i bilen kan det være en god ide allerede nu at indhente en indfrielsesopgørelse fra din långiver
      med indfrielsesdato to dage efter fremvisningsdatoen. Så er du forberedt, hvis køber ønsker at tage bilen med
      samme dag.
    </Text>

    <Title level={4}>
      Guide til fremvisning
    </Title>
    <Text>
      Det er vigtigt, at bilen fremstår flot og indbydende. En beskidt og rodet bil er meget svær at sælge.
    </Text>
    <Text>
      Så <b>inden fremvisningen</b> skal du huske at:
    </Text>

    <ul>
      <li>
        Vaske bilen
      </li>
      <li>
        Støvsuge og rengøre indvendig
      </li>
      <li>
        Rydde op i bilen
      </li>
    </ul>

    <Text>
      <b>Til selve fremvisningen:</b>
    </Text>

    <ul>
      <li>
        Lad køber gennemgå bilen grundigt både indvendig og udvendig.
      </li>
      <li>Fremhæv de ting, du selv er særligt glad for.</li>
      <li>Sørg for at servicebog, værkstedsfakturaer og andre papirer er i handskerummet.</li>
      <li>Ønsker køber en prøvetur, skal du altid køre med.</li>


      <li>
        Hvis køber har spørgsmål, du ikke kan besvare, kan I altid ringe til os på <Link href={'tel:+4552516386'}>+45
        5251 6386</Link>.
        Eller på <Link href={'tel:+4550800839'}>+45 5080 0839</Link>, hvis det er efter 17.30.
      </li>
    </ul>
    <Text>
      Husk, at du som sælger har loyal oplysningspligt overfor køber og dermed har pligt til at oplyse om samtlige fejl
      og mangler, hvad end det drejer sig om kosmetiske eller mekaniske bekendte fejl før overdragelsestidspunktet.
    </Text>

  </Flex>;
};

const SellerPrepareToSellInformation = ({ takeOverDate }: { takeOverDate?: string }) => {

  const { Text } = Typography;
  const { t } = useTranslation();


  return <Flex style={{}} gap={'small'} wrap={false} vertical={true}>

    <Text>
      Vi har fundet en køber og er blevet enige om pris og vilkår for handlen.
    </Text>
    <Text>
      Salgsdatoen er sat til: <b>{takeOverDate ? formatDate(takeOverDate) : 'Ikke sat'}</b>
    </Text>
    <Text>
      Vi mangler nu blot det juridiske og betalingen.
    </Text>
    <Text>
      Det er heldigvis både nemt og sikkert med Aksels digitale salg. Du skal blot følge de enkelte trin på denne side,
      så hjælper vi dig trygt igennem.
    </Text>
    <Text>
      Det er en god ide at forberede dig på handlen ved allerede nu at:
    </Text>

    <ol>
      <li>
        Downloade Ejerskifte app’en fra Skattestyrelsen, så du er klar til at lave ejerskiftet på overtagelsesdatoen:
        <ul style={{
          paddingTop: 8,
          paddingBottom: 8,
        }}>
          <Space size={'small'} direction={'vertical'}>
            <li>

              <Link
                href={'https://apps.apple.com/dk/app/ejerskifte/id1502217102'}
                target={'_blank'}
              ><AppleFilled style={{
                fontSize: 24,
                color: '#000',
              }} /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} App Store</Link>
            </li>

            <li>

              <Link
                href={'https://play.google.com/store/apps/details?id=dk.ufst.ejerskifte'}
                target={'_blank'}
              >
                <AndroidOutlined
                  style={{
                    fontSize: 24,
                    color: '#3DDC84',
                    paddingRight: 4,
                  }}
                /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} Google Play</Link>
            </li>

          </Space>
        </ul>
      </li>
      <li>
        <Text>Finde alle nøgler og andet udstyr, der indgår i handlen.</Text>
      </li>
    </ol>
  </Flex>;
};


const SignContractStageIcon = ({ deal, isAdmin, seller }: { deal?: Deal, isAdmin: boolean, seller: boolean }) => {
  const contractInfo = deal?.penneoSalesContractSignInformation;
  const firstOwnerDetails = seller ? contractInfo?.firstSeller : contractInfo?.firstBuyer;
  const secondOwnerDetails = seller ? contractInfo?.secondSeller : contractInfo?.secondBuyer;

  const allNeededToSign = [
    contractInfo?.firstSeller,
    contractInfo?.firstBuyer,
    contractInfo?.secondSeller,
    contractInfo?.secondBuyer,
  ].filter(c => !!c);


  if (allNeededToSign.length === 0) {
    return NotAvailableIcon;
  }

  if (allNeededToSign.length === allNeededToSign.filter(c => c?.completed).length) {
    return FinishedStepIcon;
  } else {
    const hasRejected = allNeededToSign.some(c => c?.rejected === true)
      || contractInfo?.rejected;

    if (hasRejected) {
      if (isAdmin) {
        return RequireActionIcon;
      } else {
        return WaitingIcon;
      }
    }
  }

  if (isAdmin) {
    return WaitingIcon;
  }

  if (firstOwnerDetails?.completed === false || secondOwnerDetails?.completed === false) {
    return RequireActionIcon;
  } else {
    return WaitingIcon;
  }

};


type ClientType = 'buyer' | 'seller';

type StepType = ItemType & {
  availableFor: Array<ClientType>;
  expandable: boolean;
};

const staticLegendTableColumn: TableProps<unknown>['columns'] = [
  {
    title: '',
    dataIndex: 'condition',
    key: 'condition',
    render: (text, __, idx) => {
      return <Tag color={idx === 0 ? 'red' : idx === 1 ? 'yellow' : 'green'} key={text}>{text}</Tag>;
    },
  },
  {
    title: 'Visninger per dag',
    dataIndex: 'viewsRange',
    align: 'center',
    key: 'viewsRange',
  },
  {
    title: () => {
      return <Flex wrap={false} gap={2} align={'center'} justify={'center'}>
        <div>Total gemt som favorit</div>
        <div>
          <Tooltip title={'Alle, der har gemt som favorit, får notifikation, hvis prisen justeres'}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>

      </Flex>;
    },
    align: 'center',
    dataIndex: 'favoriteRange',
    key: 'favoriteRange',
  },
];

const staticLegendTableData = [
  {
    condition: 'Kræver handling',
    viewsRange: '< 10',
    favoriteRange: '0',
  },
  {
    condition: 'Okay',
    viewsRange: '10-25',
    favoriteRange: '1-3',
  },
  {
    condition: 'God',
    viewsRange: '+25',
    favoriteRange: '+3',
  },
];

function generateEmptyDataForChart(startFrom: Date = new Date(), days: number = 7) {
  return [...Array(days).keys()].map((i) => ({
    statsForDay: new Date(startFrom.getTime() - ((days - i) * 24 * 60 * 60 * 1000)).toISOString(),
    viewsTotal: '0',
    savedAsFavorite: '0',
  }));
}

export const DealDetailsPage = () => {
  const { open } = useNotification();
  const { mutateAsync: confirmLimitIncrease, isLoading: isConfirmingLimitIncrease } = useCreate();

  const [activeKey, setActiveKey] = useState('');

  const { token } = useToken();
  const [isDownloadingFiles, setDownloadingFiles] = React.useState(false);
  const { user, isAdmin } = useAuthContext();
  useDocumentTitle({ i18nKey: 'page.dealDetails.pageMetaTitle' });
  const { t } = useTranslation();

  const { Title, Text } = Typography;
  const { confirm } = Modal;

  const go = useGo();

  const { params } = useParsed<DealDetailsParam>();

  const { mutateAsync: connectAccount, isLoading: recordSymblePayResponse } = useCreate<Deal>();
  const { mutateAsync: assignPayoutAccount } = useCreate<SymblePayRedirect>();


  const { data, isLoading, isRefetching, error, refetch: refetchDeal } = useCustom<Deal, HttpError>({
    url: `deal/${params?.id}`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id,
      refetchInterval: (d) => {
        return getStatusOrder(d?.data?.status) >= getStatusOrder(DealStatus.SELL_AGREED) ? 5000 : false;
      },
    },
  });

  if (params?.accountConnected === 'true' && !recordSymblePayResponse) {
    connectAccount({
      resource: `deal/${params.id}/connect-symblepay-account`,
      values: {},
    }).then(() => {
      assignPayoutAccount({
        successNotification: () => false,
        resource: `symble-pay/${params.id}/select-payout-account`,
        values: {},
      }).then((response) => {
        response?.data?.redirectUrl && window.open(response?.data?.redirectUrl, '_self');
      });
    }).catch(() => {
      go({
        to: `/biler/${params.id}`,
      });
    });
  } else if (params?.accountConnected === 'false') {
    open?.({
      message: 'Kontoen kunne ikke forbindes',
      description: 'Der skete en fejl under forbindelsen til kontoen. Prøv igen',
      type: 'error',
      key: 'accountConnected-false',
    });
    go({
      to: `/biler/${params.id}`,
    });
  }

  if (params?.tradeStarted === 'true' && !recordSymblePayResponse) {
    open?.({
      message: 'Succes',
      type: 'success',
      key: 'tradeStarted-true',
    });
    go({
      to: `/biler/${params.id}`,
    });
    refetchDeal();
  } else if (params?.tradeStarted === 'false') {
    open?.({
      message: 'Indbetaling ikke gennemført.',
      description: 'Prøv igen',
      type: 'error',
      key: 'tradeStarted-false',
    });
    go({
      to: `/biler/${params.id}`,
    });
  }

  if (params?.payoutAccountSelected === 'true' && !recordSymblePayResponse) {
    connectAccount({
      resource: `deal/${params.id}/connect-symblepay-account`,
      values: {},
    }).finally(() => {
      go({
        to: `/biler/${params.id}`,
      });
      return refetchDeal();
    });
  } else if (params?.payoutAccountSelected === 'false') {
    open?.({
      message: 'Kan ikke få adgang til betalingskontoen',
      description: 'Der opstod en fejl under valg af en konto til betalinger. Prøv igen',
      type: 'error',
      key: 'payoutAccountSelected-false',
    });
    go({
      to: `/biler/${params.id}`,
    });
  }

  const { data: dealAdAllTimeData, isLoading: isLoadingAllTimeData } = useCustom<DealAllTimeStats>({
    url: `deal-ad-stats/${params?.id}/all-time`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id && getStatusOrder(data?.data?.status) < getStatusOrder(DealStatus.SELL_AGREED),
    },
  });


  const { data: dealAdDailyStats } = useCustom<Array<DealDailyStats>>({
    url: `deal-ad-stats/${params?.id}/daily`,
    method: 'get',
    queryOptions: {
      enabled: !!params?.id && getStatusOrder(data?.data?.status) < getStatusOrder(DealStatus.SELL_AGREED),
    },
  });

  const deal = data?.data;

  const currentStatus = useMemo(() => {
      const seller = deal?.sellerId === user?.clientId;
      const buyer = deal?.buyerId === user?.clientId;
      return data?.data ? findStatusOrder(data?.data, seller, buyer) : 0;
    },
    [data],
  );

  useEffect(() => {

    const notExpandable = new Set([
      getStatusOrder(DealStatus.UNDER_REVIEW),
      getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE),
      getStatusOrder(DealStatus.ADVERTISEMENT_CREATED),
    ]);

    if (!(notExpandable.has(currentStatus)
      || (currentStatus === getStatusOrder(DealStatus.PAYMENT) && seller && !deal?.tradeSymblePayDetails))) {
      setActiveKey(currentStatus + '');
    }

  }, [currentStatus]);

  if (isLoading || !deal) {
    return <Skeleton type="result" />;
  }

  const seller = deal.sellerId === user?.clientId;
  const buyer = deal.buyerId === user?.clientId;

  const currentStatusOrder = findStatusOrder(deal, seller, buyer);
  const askingPrice = deal.askingPrice;
  const formattedPrice = formatPriceToDanishLocale(askingPrice);


  const hintMessageForPaymentStage = getHintMessageForPaymentStage(deal, seller, buyer, isAdmin);


  const items: Array<StepType> = ([
    {
      key: getStatusOrder(DealStatus.UNDER_REVIEW),
      availableFor: ['seller'],
      label: `${t('page.dealDetails.statusOptions.UNDER_REVIEW.BASE')} ${deal.status === DealStatus.UNDER_REVIEW
        ? `- ${t('page.dealDetails.statusOptions.UNDER_REVIEW.WAITING_FOR_ADMIN_REVIEW')}` : ''}`,
      extra: deal.status === DealStatus.UNDER_REVIEW ? WaitingIcon : FinishedStepIcon,
      showArrow: false,
      expandable: false,
      collapsible: 'icon',
    },
    {
      availableFor: ['seller'],
      key: getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE).toString(),
      label: `${t('page.dealDetails.statusOptions.WAITING_PRICE_ACCEPTANCE.BASE')} ${deal?.status === DealStatus.WAITING_PRICE_ACCEPTANCE
        ? `- ${t('page.dealDetails.statusOptions.WAITING_PRICE_ACCEPTANCE.REQUIRE_ACCEPTANCE', { price: formattedPrice })}`
        : currentStatusOrder > getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE)
          ? ''
          : `- ${t('page.dealDetails.statusOptions.COMMON.NOT_AVAILABLE_YET')}`
      }`,
      extra: deal?.status === DealStatus.WAITING_PRICE_ACCEPTANCE && seller
        ? <AcceptPriceComponent dealId={deal?.id} />
        : currentStatusOrder < getStatusOrder(DealStatus.WAITING_PRICE_ACCEPTANCE)
          ? NotAvailableIcon
          : FinishedStepIcon,
      expandable: false,
      showArrow: false,
      collapsible: 'icon',
    },
    {
      availableFor: ['seller'],
      key: getStatusOrder(DealStatus.WAITING_FOR_IMAGES).toString(),
      label: `${t('page.dealDetails.statusOptions.WAITING_FOR_IMAGES.BASE')}`,
      extra: deal.status === DealStatus.WAITING_FOR_IMAGES
        ? isAdmin ? WaitingIcon : RequireActionIcon
        : currentStatusOrder < getStatusOrder(DealStatus.WAITING_FOR_IMAGES)
          ? NotAvailableIcon
          : isAdmin ? <Tooltip
            title={'Download Alle'}
            placement={'right'}>
            <Flex align={'center'} gap={8}>
              <Button
                size={'small'}
                loading={isDownloadingFiles}
                disabled={isDownloadingFiles}
                shape={'circle'}
                type={'primary'}
                icon={<DownloadOutlined />} onClick={() => onDownloadAll(setDownloadingFiles, deal)} />
              {FinishedStepIcon}
            </Flex>
          </Tooltip> : FinishedStepIcon,
      expandable: true,
      showArrow: (currentStatusOrder >= getStatusOrder(DealStatus.WAITING_FOR_IMAGES)),
      collapsible: 'icon',
      children: <UploadImagesForm deal={deal} admin={isAdmin} onComplete={refetchDeal} />,
    },
    {
      key: getStatusOrder(DealStatus.ADVERTISEMENT_CREATED).toString(),
      label: currentStatusOrder <= getStatusOrder(DealStatus.ADVERTISEMENT_CREATED) ? t('page.dealDetails.statusOptions.ADVERTISEMENT_CREATED.BASE')
        : t('page.dealDetails.statusOptions.ADVERTISEMENT_LIVE.BASE'),
      availableFor: ['seller'],
      extra: deal?.status === DealStatus.ADVERTISEMENT_CREATED
        ? WaitingIcon
        : currentStatusOrder < getStatusOrder(DealStatus.ADVERTISEMENT_CREATED)
          ? NotAvailableIcon
          : FinishedStepIcon,
      expandable: false,
      showArrow: false,
      collapsible: 'icon',
    },
    {
      key: getStatusOrder(DealStatus.SHOWING_TO_BUYER).toString(),
      label: 'Fremvisning',
      availableFor: ['seller'],
      extra: currentStatusOrder < getStatusOrder(DealStatus.SHOWING_TO_BUYER)
        ? NotAvailableIcon
        : FinishedStepIcon,
      expandable: true,
      showArrow: true,
      collapsible: 'icon',
      children: <ShowingToBuyerInformation status={deal.status} dealId={deal.id} />,
    },
    {
      key: getStatusOrder(DealStatus.SELLER_PREPARE_TO_SELL_STAGE).toString(),
      label: 'Aftale på plads',
      availableFor: ['seller'],
      extra: currentStatusOrder < getStatusOrder(DealStatus.SELLER_PREPARE_TO_SELL_STAGE)
        ? NotAvailableIcon
        : FinishedStepIcon,
      expandable: true,
      showArrow: true,
      collapsible: 'icon',
      children: <SellerPrepareToSellInformation takeOverDate={deal.takeOverDate} />,
    },
    {
      key: getStatusOrder(DealStatus.BUYER_UPSALE_DETAILS).toString(),
      label: t('page.dealDetails.statusOptions.BUYER_UPSALE_DETAILS.BASE'),
      availableFor: ['buyer'],
      expandable: true,
      extra: <Flex wrap={false} vertical={false} gap={8}>
        {!isAdmin && !deal.buyerUpsaleDetails &&
          <Button type={'primary'} href={`/bekraeft-fremvisning/v2/${deal.id}`}>Angiv ønsker</Button>}
        {deal.buyerUpsaleDetails && FinishedStepIcon}
        {buyer && !deal.buyerUpsaleDetails && RequireActionIcon}
        {isAdmin && !buyer && !deal.buyerUpsaleDetails && WaitingIcon}
      </Flex>,
      showArrow: (isAdmin || buyer) && !!deal.buyerUpsaleDetails,
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'large'}>
        <ProDescriptions
          column={1}
          title={'Svar'}
        >
          <ProDescriptions.Item
            label={'Forsikring'}
            valueType="text">
            {deal.buyerUpsaleDetails?.wantInsuranceOffer ? t('global.yes') : t('global.no')}
          </ProDescriptions.Item>

          <ProDescriptions.Item
            label={'Ejerskifteforsikring'}
            valueType="text">
            {deal.buyerUpsaleDetails?.wantChangeOfOwnershipInsuranceOffer ? t('global.yes') : t('global.no')}
          </ProDescriptions.Item>

          {
            deal.buyerUpsaleDetails?.wantChargingStation !== null
            && deal.buyerUpsaleDetails?.wantChargingStation !== undefined
            && <ProDescriptions.Item
              label={'Ladestander'}
              valueType="text">
              {deal.buyerUpsaleDetails?.wantChargingStation ? t('global.yes') : t('global.no')}
            </ProDescriptions.Item>
          }
        </ProDescriptions>
      </Flex>,
    },
    !deal.financing ? {
      key: getStatusOrder(DealStatus.BUYER_CONFIRM_BANK_LIMIT_INCREASE).toString(),
      label: 'Forberedelse til handel',
      availableFor: ['buyer'],
      expandable: true,
      extra: <Flex wrap={false} vertical={false} gap={8}>
        {
          buyer && !deal.buyerConfirmedLimitIncrease &&
          <>
            <Button
              loading={isConfirmingLimitIncrease}
              onClick={() => {
                confirm({
                  icon: <InfoCircleOutlined />,
                  title: 'Ved at klikke OK bekræfter du, at du har hævet beløbsgrænsen for overførsler i din bank og kan gennemføre betalingen.',
                  onOk() {
                    return confirmLimitIncrease({
                      resource: 'deal/' + deal.id + '/confirm-limit-increase',
                      values: {},
                    }).then(() => refetchDeal());
                  },
                });
              }}
              type={'primary'}>Beløbsgrænse hævet
            </Button>
            {RequireActionIcon}
          </>
        }
        {
          deal.buyerConfirmedLimitIncrease && FinishedStepIcon
        }
        {
          isAdmin && !deal.buyerConfirmedLimitIncrease && WaitingIcon
        }
      </Flex>,
      showArrow: true,
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'middle'}>
        <Text>
          Vi er blevet enige med sælger om pris og vilkår for handlen og salgsdatoen er sat
          til <b>{deal.takeOverDate ? formatDate(deal.takeOverDate) : 'Ikke sat'}</b>.
        </Text>

        <Text>
          Vi mangler nu blot det juridiske og betalingen.
        </Text>
        <Text>
          Det er heldigvis både nemt og sikkert med Aksels digitale salg. Du skal blot følge de enkelte trin på denne
          side, så hjælper vi dig trygt igennem.
        </Text>
        <Text>
          Til at starte med skal du <b>hæve din beløbsgrænse for straksoverførsler i din bank</b>, så du kan gennemføre
          betalingen.
        </Text>
        <Text>
          Du skal hæve beløbsgrænsen fra 1 dag før salgsdatoen til 1 dag efter.
        </Text>
        <Text>
          Når du har hævet beløbsgrænsen skal du bekræfte det på knappen ovenfor, så vi ved, at handlen kan fortsætte.
        </Text>

        <Text>
          Derudover er det en god ide at downloade Ejerskifte app’en fra Skattestyrelsen, så du er klar til at lave
          ejerskiftet på overtagelsesdatoen:
        </Text>
        <ul style={{
          paddingTop: 8,
          paddingBottom: 8,
        }}>
          <Space size={'small'} direction={'vertical'}>
            <li>

              <Link
                href={'https://apps.apple.com/dk/app/ejerskifte/id1502217102'}
                target={'_blank'}
              ><AppleFilled style={{
                fontSize: 24,
                color: '#000',
              }} /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} App Store</Link>
            </li>

            <li>

              <Link
                href={'https://play.google.com/store/apps/details?id=dk.ufst.ejerskifte'}
                target={'_blank'}
              >
                <AndroidOutlined
                  style={{
                    fontSize: 24,
                    color: '#3DDC84',
                    paddingRight: 4,
                  }}
                /> {t('page.buyerCarForm.goodToDo.ownershipTransferAppName')} Google Play</Link>
            </li>

          </Space>
        </ul>
      </Flex>,
    } : undefined,
    !deal.financing ? {
      key: getStatusOrder(DealStatus.CONNECT_SYMBLE_PAY).toString(),
      label: <>
        {t('page.dealDetails.statusOptions.CONNECT_SYMBLE_PAY.BASE')}
      </>,
      availableFor: ['seller', 'buyer'],
      expandable: true,
      collapsible: 'icon',
      extra: <ConnectSymblePayStageExtra deal={deal} refetchDeal={refetchDeal}></ConnectSymblePayStageExtra>,
      children: isAdmin ? <Flex wrap={false} vertical={true} gap={'middle'}>
          <Title level={3}>{t('symblepay.sellerSymblePayDetails')}</Title>
          <PaymentStageInfo admin={isAdmin} deal={deal} seller={true}></PaymentStageInfo>
          <Title level={3}>{t('symblepay.buyerSymblePayDetails')}</Title>
          <PaymentStageInfo admin={isAdmin} deal={deal} seller={false}></PaymentStageInfo>
        </Flex> :
        <Flex wrap={false} vertical={true} gap={'middle'}>
          <PaymentStageInfo deal={deal} seller={seller} buyer={buyer}></PaymentStageInfo>
        </Flex>,
    } : undefined,
    !deal.financing && deal.debt ? {
      key: getStatusOrder(DealStatus.REDEMPTION_STATEMENT).toString(),
      label: currentStatusOrder <= getStatusOrder(DealStatus.SELL_AGREED) ?
        t('page.dealDetails.statusOptions.REDEMPTION_STATEMENT.BASE') + addDashPrefixIfNotEmpty(t(getRedemptionStatementHintText(deal, seller, isAdmin))) : t('page.dealDetails.statusOptions.REDEMPTION_STATEMENT.PROVIDED'),
      availableFor: ['seller'],
      expandable: true,
      extra: <Flex wrap={false} vertical={false} gap={8}>
        <RedemptionStatementActions
          deal={deal}
          refetchDeal={refetchDeal}
          admin={isAdmin}
          seller={seller}>
        </RedemptionStatementActions>
        {redemptionStatementIcon({ deal, seller, admin: isAdmin })}
      </Flex>,
      showArrow: true,
      collapsible: 'icon',
      children: <RedemptionStatementInfo deal={deal}></RedemptionStatementInfo>,
    } : undefined,
    !deal.financing ? {
      key: getStatusOrder(DealStatus.SECOND_OWNER_DETAILS).toString(),
      label: t('page.dealDetails.statusOptions.SECOND_OWNER_DETAILS.BASE'),
      availableFor: ['seller', 'buyer'],
      expandable: true,
      extra: <Flex wrap={false} vertical={false} gap={8}>
        {!isAdmin && <SecondOwnerActions deal={deal} refetchDeal={refetchDeal}
                                         seller={seller}>

        </SecondOwnerActions>}
        {secondOwnerDetailsIcon({ deal, seller, admin: isAdmin, buyer })}
      </Flex>,
      showArrow: true,
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'large'}>
        {seller && <Flex vertical={true} gap={'small'}>
          <Text>
            Hvis der er en sekundær ejer registreret i bilen (f.eks. ægtefælle eller partner), skal du tilføje
            oplysninger på vedkommende, så de indgår i og kan underskrive slutseddel.
          </Text>
          <Text>
            Hvis du er den eneste ejer af bilen, vælger du blot ‘Ingen sekundær ejer’.
          </Text>
        </Flex>}

        {
          buyer && <Flex vertical={true} gap={'small'}>
            <Text>
              Hvis der skal være en sekundær ejer registreret i bilen (f.eks. ægtefælle eller partner), skal du tilføje
              oplysninger på vedkommende, så de indgår i og kan underskrive slutseddel.
            </Text>
            <Text>
              Hvis du skal være den eneste ejer af bilen, vælger du blot ‘Ingen sekundær ejer’.
            </Text>
          </Flex>
        }


        {(isAdmin || seller) && deal?.secondSellerDetails
          && <SecondOwnerDetailsInfo
            allowEdit={seller && !isAdmin && currentStatusOrder === getStatusOrder(DealStatus.SELL_AGREED)}
            dealId={deal?.id}
            onComplete={refetchDeal}
            title={'Sekundær ejer, sælger'}
            details={deal.secondSellerDetails} />}
        {(isAdmin || !seller) && deal?.secondBuyerDetails
          && <SecondOwnerDetailsInfo
            dealId={deal?.id}
            onComplete={refetchDeal}
            allowEdit={!seller && !isAdmin && currentStatusOrder === getStatusOrder(DealStatus.SELL_AGREED)}
            title={'Sekundær ejer, køber'}
            details={deal.secondBuyerDetails} />}
      </Flex>,
    } : undefined,
    !deal.financing ? {
      key: getStatusOrder(DealStatus.SIGNING_CONTRACT).toString(),
      label: currentStatusOrder <= getStatusOrder(DealStatus.SIGNING_CONTRACT) ?
        t('page.dealDetails.statusOptions.SIGNING_CONTRACT.BASE')
        : t('page.dealDetails.statusOptions.SIGNING_CONTRACT.DONE'),
      availableFor: ['seller', 'buyer'],
      expandable: true,
      extra: <SignContractStageExtra isAdmin={isAdmin} seller={seller} deal={deal}
                                     refetchDeal={refetchDeal}></SignContractStageExtra>,
      showArrow: (currentStatusOrder >= getStatusOrder(DealStatus.SIGNING_CONTRACT)),
      collapsible: 'icon',
      children: <Flex vertical={true} gap={'large'}>
        <SignContractInfo title={'Sælger'}
                          details={deal?.penneoSalesContractSignInformation?.firstSeller}></SignContractInfo>
        <SignContractInfo title={'Køber'}
                          details={deal?.penneoSalesContractSignInformation?.firstBuyer}></SignContractInfo>
        <SignContractInfo title={'Sælger 2'}
                          details={deal?.penneoSalesContractSignInformation?.secondSeller}></SignContractInfo>
        <SignContractInfo title={'Køber 2'}
                          details={deal?.penneoSalesContractSignInformation?.secondBuyer}></SignContractInfo>

        {deal?.penneoSalesContractSignInformation?.signedContract && <ProDescriptions
          column={1}
          title={''}
        >
          <ProDescriptions.Item
            label={'Download underskrevet slutseddel'}
            valueType="text"
          >
            <DefaultButton type="link"
                           size={'small'}
                           icon={<DownloadOutlined />}
                           href={`${dataProvider.getApiUrl()}/file/dealer-car/download-file/${deal?.penneoSalesContractSignInformation?.signedContract?.urlKey}`}
                           target="_blank"></DefaultButton>
          </ProDescriptions.Item>

        </ProDescriptions>}


      </Flex>,
    } : undefined,
    !deal.financing ? {
      key: getStatusOrder(DealStatus.PAYMENT).toString(),
      label: currentStatusOrder <= getStatusOrder(DealStatus.PAYMENT) ?
        <div>{t('page.dealDetails.statusOptions.SELL_AGREED.BASE')} {hintMessageForPaymentStage ? ' - ' : ''} {hintMessageForPaymentStage}</div>
        : t('page.dealDetails.statusOptions.SELL_AGREED.BASE'),
      availableFor: ['seller', 'buyer'],
      expandable: buyer || isAdmin || (seller && !!deal.tradeSymblePayDetails),
      extra: <TradeStageExtra refetchDeal={refetchDeal} deal={deal}></TradeStageExtra>,
      showArrow:
        (buyer || isAdmin || (seller && !!deal.tradeSymblePayDetails)) &&
        (currentStatusOrder >= getStatusOrder(DealStatus.PAYMENT)),
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'middle'}>
        {seller && deal.tradeSymblePayDetails && <Flex vertical={true} gap={'small'}>
          <Text>
            Pengene står på sikringskontoen hos Safepay og du er nu garanteret pengene, såfremt du overdrager bilen til
            køber. Du vil have pengene på din konto den næste bankdag.
          </Text>
          <Text>
            Evt. pant til långiver<b>*</b> og salær til Aksel vil automatisk blive modregnet.
          </Text>
          <Text italic>
            <b>*</b>Hvis din långiver anvender indbetalingskort til indfrielse skal køber selv lave indfrielsen. I de
            tilfælde vil Aksel have orienteret jer om det og det vil fremgå af slutsedlen.
          </Text>
        </Flex>}
        {buyer && <Flex vertical={true} gap={'small'}>
          <Text>
            Når pengene står på sikringskontoen hos Safepay, bliver de først overført til sælger når du har fået
            overdraget bilen og der er lavet officielt ejerskifte.
          </Text>
          <Text>
            Hvis du af den ene eller anden grund ikke får overdraget og ejerskiftet bilen, annulleres betalingen og
            pengene bliver tilbageført til din konto.
          </Text>
          <Text>
            Ved store overførsler foretager de fleste banker stikprøvekontroller for at lave sikkerheds- og
            hvidvasktjek.
            I de tilfælde vil banken midlertidigt tilbageholde indbetalingen til Safepay indtil kontrollen er
            gennemført.
            Det tager typisk 10 min til 1 time. Hvis du oplever det kan du ringe til din egen bank og høre nærmere.
          </Text>
          <Text>
            Evt. pant i bilen* vil automatisk blive indfriet.
          </Text>
          <Text italic>
            <b>*</b>Hvis sælgers långiver anvender indbetalingskort til indfrielse af pant skal du selv lave
            indfrielsen. I de tilfælde vil Aksel have orienteret jer om det og det vil fremgå af slutsedlen.
          </Text>

        </Flex>}

        <PaymentStageTradeDetails showPayeesDetails={isAdmin || seller} deal={deal}></PaymentStageTradeDetails>
      </Flex>,
    } : undefined,
    !deal.financing ? {
      key: getStatusOrder(DealStatus.CONFIRM_OWNERSHIP_CHANGE).toString(),
      label: t('page.dealDetails.statusOptions.CONFIRM_OWNERSHIP_CHANGE.BASE'),
      availableFor: ['seller', 'buyer'],
      expandable: true,
      extra: <ConfirmOwnershipChangeStageExtra refetchDeal={refetchDeal}
                                               deal={deal}></ConfirmOwnershipChangeStageExtra>,
      showArrow: (currentStatusOrder >= getStatusOrder(DealStatus.CONFIRM_OWNERSHIP_CHANGE)),
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'middle'}>
        <ConfirmOwnershipChangeDetails seller={seller} buyer={buyer}></ConfirmOwnershipChangeDetails>
      </Flex>,
    } : undefined,
    {
      key: getStatusOrder(DealStatus.SOLD).toString(),
      label: t('page.dealDetails.statusOptions.SOLD.BASE'),
      availableFor: ['seller', 'buyer'],
      expandable: true,
      extra: currentStatusOrder >= getStatusOrder(DealStatus.SOLD) ? FinishedStepIcon : NotAvailableIcon,
      showArrow: (!isAdmin && currentStatusOrder >= getStatusOrder(DealStatus.SOLD)),
      collapsible: 'icon',
      children: <Flex wrap={false} vertical={true} gap={'middle'}>
        {seller && <CarSoldSellerInfo></CarSoldSellerInfo>}
        {buyer && <CarSoldBuyerInfo></CarSoldBuyerInfo>}
      </Flex>,
    },
  ] satisfies Array<StepType | undefined> as Array<StepType>).filter(v => !!v).filter((item) => isAdmin || (seller ? item.availableFor.includes('seller') : item.availableFor.includes('buyer')));


  if (error) {
    return <ErrorComponent />;
  }

  return <div>
    <Flex
      justify={'space-between'}
      align={'flex-start'}
      wrap={true}
    >
      <DefaultButton
        style={{
          alignSelf: 'flex-start',
          marginBottom: '1rem',
        }}
        icon={<HomeOutlined color={token.colorPrimary} />} onClick={() => go({
        to: isAdmin ? '/admin/biler' : '/biler',
      })} />
      <Flex gap={'small'} vertical={false} wrap={true}>

        {isAdmin && <>
          <EditDealFormModal deal={deal} onComplete={refetchDeal} />
          <ChangeUserDataForm
            title={t('global.seller')}
            clientId={deal.sellerId}
          />
          {deal.buyerId && <ChangeUserDataForm
            title={t('global.buyer')}
            clientId={deal.buyerId}
          />}
        </>}

        {isRefetching
          ? <LoadingComponent size={'small'} justify={'right'} /> :
          <div style={{ width: 14 }}></div>

        }
      </Flex>

    </Flex>

    <CarInfo {...deal} />

    {((seller || isAdmin) && currentStatusOrder < getStatusOrder(DealStatus.SELL_AGREED)) && <Collapse
      style={{ marginLeft: '-16px' }}
      expandIcon={({ isActive }) => (
        <div style={{ marginTop: '6px' }}><RightOutlined
          rotate={isActive ? 90 : 0}
        /></div>
      )}
      defaultActiveKey={[deal?.status === DealStatus.ADVERTISEMENT_LIVE ? '1' : '2']} ghost items={[
      {
        key: '1',
        label: <Title style={{ marginBottom: 0 }} level={3}> Statistik</Title>,
        children: <div>
          <Row gutter={16} align={'middle'} style={{ marginBottom: '3rem', marginLeft: '1rem' }}>
            <Col flex={1}>
              <Statistic
                style={{ marginLeft: '-8px' }}
                loading={isLoadingAllTimeData}
                title="Liggetid"
                value={dealAdAllTimeData?.data?.daysOnMarket || 0}
                prefix={<FieldTimeOutlined />} />
            </Col>
            <Col flex={1}>
              <Statistic
                loading={isLoadingAllTimeData}
                title="Visninger"
                value={dealAdAllTimeData?.data?.viewsTotal || 0}
                prefix={<EyeOutlined />} />
            </Col>
            <Col flex={1}>
              <Statistic
                loading={isLoadingAllTimeData}
                title="Gemt som favorit"
                value={dealAdAllTimeData?.data?.savedAsFavorite || 0}
                prefix={<HeartOutlined />} />
            </Col>
          </Row>
          <Title level={5} style={{ paddingLeft: '1rem' }}>
            {
              dealAdAllTimeData?.data?.updatedAt && `Sidst opdateret ${new Date(dealAdAllTimeData?.data?.updatedAt || '').toLocaleString('en-GB')}`
              || `Statistik er først tilgængelig når annoncen er live`
            }
          </Title>
          <ColumnChart
            data={(dealAdDailyStats?.data?.length || 0 > 0
              ? [...((dealAdDailyStats?.data?.length || 0) < 7 ? generateEmptyDataForChart(
                new Date(dealAdDailyStats?.data[0]?.statsForDay || 0),
                7 - (dealAdDailyStats?.data?.length || 0),
              ) : []), ...dealAdDailyStats?.data || []]
              : generateEmptyDataForChart()).flatMap((item) => {

              const date = new Date(item.statsForDay).toLocaleDateString('da-DK', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              });

              return [
                {
                  viewsTotal: Number.parseInt(item.viewsTotal),
                  type: 'Visninger',
                  statsForDay: date,
                },
                {
                  viewsTotal: Number.parseInt(item.savedAsFavorite),
                  type: 'Favorit',
                  statsForDay: date,
                },
              ];
            })}
            interaction={{
              tooltip: { shared: true },
              elementHighlight: { background: true },
            }}
            stack={true}
            colorField={'type'}
            xField={'statsForDay'}
            yField={'viewsTotal'}
            scale={{
              color: { range: [token.colorPrimary, token.colorWarning] },
            }}
          ></ColumnChart>
          <Table
            style={{ maxWidth: '512px', marginLeft: '1rem' }}
            dataSource={staticLegendTableData}
            columns={staticLegendTableColumn}
            pagination={false}
          />

        </div>,
      },
    ]}>
    </Collapse>}


    <Title style={{ marginTop: '1rem' }} level={3}>Proces</Title>

    <Collapse
      style={{
        marginTop: '1rem',
      }}
      accordion
      onChange={(key) => {
        setActiveKey(key.toString());
      }}
      activeKey={activeKey}
      items={items}
    >
    </Collapse>
  </div>;

};
